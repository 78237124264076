@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.layout {
    @include grid-column;

    //flex-grow: 1;
    //flex-shrink: 0;
    min-height: 100%;
}

.default-layout {
    @include grid-column;

    min-height: 500px;
    transition: opacity 0.4s $default-transition,
        transform 0.2s $default-transition;

    @media (max-width: $desktop) {
        min-height: 0;
    }

    @media (max-width: $tablet) {
        display: block;
        overflow: hidden;
    }
}
