@import "../variables";
@import "../utilities/mixins";

input,
textarea {
    font-family: $main-font;
    font-size: $text-size-normal;
}

.form {
    @include grid-row;

    flex: 1;
    flex-wrap: wrap;
}

.input-container {
    @include grid-column;
}

.button-container {
    @include grid-row;

    align-items: flex-start;
    justify-content: flex-start;
}

.checkbox {
    @include grid-row;

    align-items: flex-start;
    cursor: pointer;
    margin-bottom: 32px;
    user-select: none;

    @media (max-width: $mobile) {
        margin-bottom: 16px;
    }
}

.form-group {
    @include grid-row;

    justify-content: space-between;
    margin-bottom: 24px;

    @media (max-width: $tablet) {
        flex-direction: column;
        margin-bottom: 12px;

        &.field:first-child {
            margin-bottom: 12px;
        }
    }
}

.field {
    @include grid-row;
}

.control {
    @include grid-column;
}

.input {
    font-family: $main-font;
    font-size: $text-size-normal;
}

.label {
    font-size: $text-size-normal;
    font-weight: bold;
    margin-bottom: 8px;
}

.select {
    @include grid-row;

    align-items: center;
    background-color: $white;
    height: 50px;
    justify-content: space-between;
    padding: 0 11px;
    position: relative;
    width: 100%;

    span {
        font-size: $text-size-normal;
        font-weight: bold;
    }

    select {
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    svg {
        color: $blue-light;
        height: 23px;
        width: 14px;
    }
}

.custom-file-input {
    background: none;
    border:none;
    color: transparent;
    outline: none;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input::before {
    content: "Uploaden";
    color: $blue-lighter;
    display: inline-block;
    background: $white;
    padding: 10px 30px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-weight: bold;
    font-size: $text-size-medium;
}
