@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.hero {
    @include bg-purple;
}

.wrapper {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    max-height: 400px;
}

.content {
    align-self: flex-start;
    color: $white;
    max-width: 630px;
    min-width: 630px;
    padding: 56px 0;

    @media (max-width: $widescreen) {
        max-width: 430px;
        min-width: 430px;
    }

    @media (max-width: $desktop) {
        margin-bottom: 0;
        max-width: 630px;
        min-width: 630px;
    }

    @media (max-width: $tablet) {
        min-width: 100%;
    }
}

.title {
    color: $yellow-lighter;
    font-size: 4.375em;
    hyphens: auto;

    @media (max-width: $tablet) {
        font-size: 2.1875em;
    }
}

.description {
    margin-top: 24px;

    h2 {
        margin-bottom: 24px;
    }

    p {
        margin-bottom: 24px;
    }
}

.content :global(.button) {
    min-width: 1px;
    width: auto;
}

.image {
    height: auto;
    max-height: 400px;
    max-width: 500px;
    object-fit: contain;
    position: relative;
    width: 100%;
    z-index: 2;

    @media (max-width: $tablet) {
        display: none;
    }
}

.hero--no-margin {
    margin-bottom: 0;
}

.hero--purple {
    @include bg-purple;
}

.hero--black {
    @include bg-black;
}

.hero--yellow {
    @include bg-yellow;
}

.hero--yellow-light {
    @include bg-yellow-light;
}

.hero--blue {
    @include bg-blue;
}

.hero--blue-light {
    @include bg-blue-light;
}

.hero--red {
    @include bg-red;
}
