@import "../../styles/variables";

.pagination {
    color: $white;
    display: flex;
    margin-top: 40px;

    @media (max-width: $tablet) {
        margin-top: 32px;
    }
}

.pagination a,
.pagination span {
    align-items: center;
    color: $white;
    display: flex;
    height: 40px;
    justify-content: center;
    text-align: center;
    width: auto;
}

.pages {
    align-items: center;
    display: flex;
    font-size: 1.5em;
    font-weight: 900;

    @media (max-width: $tablet) {
        font-size: 1.125em;
        overflow: auto;
    }
}

.pages li {
    margin-right: 22px;
    position: relative;

    @media (max-width: $tablet) {
        margin-right: 10px;
    }

    &:last-of-type {
        margin-right: 0;
    }
}

.pages li a {
    cursor: pointer;
    white-space: nowrap;
}

.pages li.page-active {
    opacity: 1;
}

.pages li.page-active a {
    background: $black;
    width: 40px;
}

.pagination .button {
    cursor: pointer;
    height: 40px;
    margin-left: 22px;
    width: 40px;
}

.pagination .button svg {
    color: $blue;
    fill: currentColor;
    height: 15px;
    transition: transform 0.2s ease;
    width: 34px;
}

.pagination .button--prev {
    margin-left: 0;
    margin-right: 22px;
}

.pagination .button--prev svg {
    transform: rotate(180deg);
}

.pagination--purple .pages li.page-active a {
    background: $white;
}

.pagination--purple a,
.pagination--purple span {
    color: $black;
}

.pagination--black .pages li.page-active a {
    color: $white;
}

.pagination--black a,
.pagination--black span {
    color: $black;
}
