@import "../../styles/variables";

.team {
    background: $black;
    color: $white;
    padding: 56px 0;
    width: 100%;
}

.header {
    composes: header from "../../components/heading-cta/styles.module.scss";

    @media (max-width: $desktop) {
        align-items: flex-start;
        flex-flow: column;
    }
}

.title {
    composes: title from "../../components/heading-cta/styles.module.scss";
}

.more {
    composes: more from "../../components/heading-cta/styles.module.scss";
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.2s $default-transition,
        transform 0.2s $default-transition;

    @media (max-width: $tablet) {
        margin-top: 24px;
    }

    &::after {
        display: none;
    }
}

.more--prev:hover {
    transform: translateX(-8px);
}

.more--disabled {
    opacity: 0.65;
    pointer-events: none;
}

.heading {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    @media (max-width: $desktop) {
        align-items: flex-start;
        flex: 1;
        flex-wrap: wrap;
    }

    @media (max-width: $tablet) {
        flex-flow: column;
    }
}

.categories {
    margin-bottom: 24px;
    user-select: none;

    @media (max-width: $widescreen) {
        margin-bottom: 0;
    }

    @media (max-width: $desktop) {
        flex: 1;
        flex-wrap: wrap;
        margin-top: 14px;
    }

    @media (max-width: $tablet) {
        margin-top: 4px;
    }

    @media (max-width: $mobile) {
        margin-left: 0;
        margin-top: 24px;
    }
}

.categories span {
    cursor: pointer;
    display: inline-block;
    font-size: 1.25em;
    font-weight: bold;
    margin-bottom: 24px;
    margin-right: 28px;
    position: relative;

    @media (max-width: $mobile) {
        margin-right: 22px;
    }

    &:last-of-type {
        margin-right: 0;
    }

    &::after {
        background: $blue-light;
        bottom: -5px;
        content: "";
        height: 2px;
        left: 0;
        position: absolute;
        transition: width 0.2s $default-transition;
        width: 0;
    }
}

.categories .is-active {
    color: $blue-light;
    pointer-events: none;

    &::after {
        width: 100%;
    }
}

.slider {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.slide-items {
    align-items: flex-start;
    box-sizing: content-box;
    display: flex;
    position: relative;
    transition-property: transform;
    width: 100%;
    z-index: 1;
}

.loading {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 54px;
    margin-top: 54px;
    width: 100%;
}

.navigation {
    display: flex;

    @media (max-width: $tablet) {
        justify-content: center;
    }
}
