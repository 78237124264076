@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.cta {
    background: $blue-bis;
    color: $white;
    overflow: hidden;
    padding-top: 68px;
    position: relative;
    z-index: 2;

    @media (max-width: 920px) {
        padding-top: 54px;
    }
}

.wrapper {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    position: relative;

    @media (max-width: 920px) {
        align-items: flex-start;
        flex-flow: column;
    }
}

.content {
    margin-bottom: 167px;
    margin-right: 96px;
    max-width: 360px;
    min-width: 360px;
    padding-top: 54px;
    position: relative;
    z-index: 2;

    @media (max-width: 1240px) {
        margin-bottom: 56px;
        margin-right: 32px;
        max-width: 320px;
        min-width: 320px;
    }

    @media (max-width: 920px) {
        margin-bottom: 54px;
        max-width: 360px;
        min-width: 360px;
        padding-top: 0;
    }

    @media (max-width: 400px) {
        min-width: 100%;
    }
}

.title {
    font-size: 3.125em;
    line-height: 1;
    margin-bottom: 28px;
    padding-left: 24px;
    position: relative;

    @media (max-width: $desktop) {
        font-size: 2.1875em;
        line-height: 1.11;
        margin-bottom: 18px;
    }

    &::before {
        background: $black;
        content: "";
        height: calc(100% - 13px);
        left: 0;
        position: absolute;
        top: 8px;
        width: 4px;
    }
}

.description {
    font-size: $text-size-normal;
    line-height: 1.63;

    @media (max-width: $desktop) {
        line-height: 1.75;
    }
}

.content :global(.button) {
    margin-top: 24px;
}

.image {
    border: 0;
    height: auto;
    margin-right: 132px;
    max-width: 500px;
    position: relative;
    width: calc(100% - 360px - 96px - 132px);
    z-index: 2;

    @media (max-width: 1240px) {
        margin-right: 32px;
        width: calc(100% - 320px - 32px - 32px);
    }

    @media (max-width: $desktop) {
        margin-right: 0;
        width: calc(100% - 320px - 32px);
    }

    @media (max-width: 920px) {
        margin-right: 0;
        max-width: 540px;
        width: 100%;
    }

    @media (max-width: 520px) {
        left: -16px;
        position: relative;
        width: calc(100% + 32px);
    }
}

.gradient {
    background: radial-gradient(
        circle,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 70%
    );
    bottom: -228px;
    height: 914px;
    opacity: 0.4;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 914px;
    z-index: 1;

    @media (max-width: 920px) {
        bottom: -457px;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

.cta--purple {
    @include bg-purple;
}

.cta--black {
    @include bg-black;
}

.cta--blue {
    @include bg-blue;
}

.cta--large-image .image {
    margin-right: -126px;
    max-width: 910px;
    width: calc(100% - 360px - 96px + 126px);

    @media (max-width: 1280px) {
        margin-right: 0;
        width: calc(100% - 320px - 32px);
    }

    @media (max-width: 920px) {
        max-width: 744px;
        width: 100%;
    }

    @media (max-width: 520px) {
        width: calc(100% + 32px);
    }
}

.cta--large-image .content {
    margin-bottom: 104px;
    margin-right: 96px;
    max-width: 360px;
    min-width: 360px;
    padding-top: 56px;
    position: relative;
    z-index: 2;

    @media (max-width: 1240px) {
        margin-bottom: 56px;
        margin-right: 32px;
        max-width: 320px;
        min-width: 320px;
    }

    @media (max-width: 920px) {
        margin-bottom: 56px;
        max-width: 360px;
        min-width: 360px;
        padding-top: 0;
    }

    @media (max-width: 400px) {
        min-width: 100%;
    }
}

.video {
    align-self: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    @media (max-width: 920px) {
        margin-bottom: 1rem;
    }

    video {
        max-width: 100%;
    }
}
