@import "../../styles/variables";

.header {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    @media (max-width: $tablet) {
        margin-bottom: 18px;
    }

    @media (max-width: $mobile) {
        align-items: flex-start;
        flex-direction: column;
    }
}

.title {
    color: currentColor;
    font-size: 2.5em;
    font-weight: bold;

    @media (max-width: $tablet) {
        font-size: $text-size-large;
    }
}

.more {
    color: currentColor;
    display: inline-block;
    font-size: $text-size-medium;
    font-weight: bold;
    padding-bottom: 8px;
    position: relative;
    transition: opacity 0.2s $default-transition;
    white-space: nowrap;

    &:hover {
        opacity: 0.8;
    }

    svg {
        margin-left: 12px;
        transition: transform 0.2s $default-transition;
    }

    &:hover svg {
        transform: translateX(4px);
    }

    @media (max-width: $desktop) {
        margin-top: 16px;
    }

    &::after {
        background: currentColor;
        bottom: 0;
        content: "";
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
    }
}
