@import "../variables";
//@import "~swiper/swiper";

.swiper-container {
    overflow: visible !important;
}

.swiper-button-prev {
    --swiper-navigation-size: 75px;
    --swiper-navigation-prevx: calc(var(--swiper-navigation-size) * -1);

    color: $yellow !important;
    transform: translateX(var(--swiper-navigation-prevx)) translateY(-50%);
}

.swiper-button-next {
    --swiper-navigation-size: 75px;
    --swiper-navigation-nextx: var(--swiper-navigation-size);

    color: $yellow !important;
    transform: translateX(var(--swiper-navigation-nextx)) translateY(-50%);
}

.swiper-button-prev,
.swiper-button-next {
    @media (max-width: $tablet) {
        --swiper-navigation-size: 40px;

        transform: translateY(-50%);
    }
}

[class$=yellow] {
    .swiper-button-prev,
    .swiper-button-next {
        color: $white !important;
    }
}
