@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.intro {
    background: linear-gradient(
        to right,
        $light,
        $light 50%,
        $yellow-light 50%,
        $yellow-light 100%
    );
    box-sizing: border-box;
    position: relative;

    @media (max-width: $desktop) {
        background: $light;
    }

    .wrapper {
        @include bg-black;

        max-width: 1432px;
    }
}

.hero {
    @include bg-image;

    width: 100%;
    height: 300px;

    @media (max-width: $tablet) {
        height: 200px;
    }
}

.breadcrumbs {
    display: flex;
    padding: 3rem 3rem 0;
    position: relative;
}

.title {
    line-height: 2;
    margin: 0 3rem;

    @media (max-width: $tablet) {
        color: $red;
        font-size: $text-size-large;
    }
}

.wrapper {
    box-sizing: border-box;
    margin-top: -100px;
    max-width: 1400px;
    position: relative;

    a {
        color: $white;
    }

    ul {
        list-style: disc;
    }

    @media (max-width: $tablet) {
        margin-top: -50px;
    }
}

.summary {
    display: grid;
    font-weight: bold;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 3rem 3rem;

    @media (max-width: $tablet) {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}

.left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: $tablet) {
    }

    .more {
        color: $yellow;
        cursor: pointer;
        font-size: $text-size-normal;
        text-decoration: underline;

        @media (max-width: $tablet) {
            display: none;
        }
    }
}

.details {
    color: $white;
    display: flex;
    flex-direction: column;
    font-size: $text-size-normal;
    font-weight: bold;
    line-height: 27px;
    margin-left: 2rem;

    span {
        color: $yellow;
    }

    @media (max-width: $tablet) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-left: 0;

        div {
            display: flex;
            flex-direction: column;
        }
    }
}

.content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 1400px;
    margin-top: 2rem;

    ul {
        list-style: disc;
    }

    @media (max-width: $desktop) {
        grid-template-columns: 1fr;
    }
}

.recruiter {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 2rem;
    padding: 0 2rem;

    a {
        color: $black;
        font-size: $text-size-normal;
    }

    .recruiterDetails {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        gap: 0.5rem;
        padding-bottom: 5rem;

        svg {
            height: 24px;
            width: 24px;
        }
    }

    .recruiterTitle {
        color: $purple;
        line-height: 1.5;
        padding-bottom: 1rem;
    }
}
