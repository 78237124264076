@import "../../../styles/variables";
@import "../../../styles/utilities/mixins";

.recruiter {
    column-gap: 6rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: $tablet) {
        grid-template-columns: 1fr;
    }
}

.title {
    font-size: 2rem;
    font-weight: bold;
    grid-column: span 2 / span 2;

    @media (max-width: $tablet) {
        grid-column: 1;
    }
}

.content {
    font-size: $text-size-large;
    font-weight: bold;
    margin-top: 3rem;

    & > * {
        font-size: $text-size-large;
    }

    @media (max-width: $tablet) {
        margin-top: 1rem;
    }
}

.author {
    align-items: flex-end;
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    padding: 0 2rem;

    @media (max-width: $tablet) {
        padding: 0;

        img {
            display: none;
        }
    }

    img {
        max-width: 200px;
    }

    a {
        color: currentColor;
        font-size: $text-size-normal;
    }

    .recruiterDetails {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        gap: 0.5rem;
        justify-content: flex-end;
        padding-bottom: 1rem;

        svg {
            height: 24px;
            width: 24px;
        }
    }

    .recruiterTitle {
        color: $purple;
        line-height: 1.5;
        padding-bottom: 1rem;
    }
}
