@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.modal {
    align-items: flex-end;
    background: $yellow-light;
    display: flex;
    padding-top: 108px;

    @media (max-width: $widescreen) {
        min-height: 200px;
        padding-top: 88px;
    }

    @media (max-width: $desktop) {
        align-items: flex-start;
        flex-flow: column-reverse;
    }

    @media (max-width: $tablet) {
        padding-top: 64px;
    }
}

.image {
    margin-right: 24px;
    max-width: calc(100% - 500px - 24px);
    width: calc(100% - 500px - 24px);

    @media (max-width: $widescreen) {
        max-width: calc(100% - 400px - 24px);
        width: calc(100% - 400px - 24px);
    }

    @media (max-width: $desktop) {
        margin-right: 0;
        max-width: 615px;
        min-width: 1px;
        width: 100%;
    }
}

.image--recruiter {
    @media (max-width: $tablet) {
        display: none;
    }
}

.form {
    align-self: flex-start;
    margin-bottom: 58px;
    margin-top: 24px;
    min-width: 500px;
    padding-right: 92px;
    user-select: none;
    width: 500px;

    @media (max-width: $widescreen) {
        min-width: 400px;
        width: 400px;
    }

    @media (max-width: $desktop) {
        margin-bottom: 32px;
        margin-top: 0;
        min-width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        width: 100%;
    }

    @media (max-width: $mobile) {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.form input,
.form textarea {
    font-size: 1.125em;

    @media (max-width: $tablet) {
        font-size: 1em;
    }
}

.steps {
    background-color: rgba(0, 0, 0, 0.2);
    margin-bottom: 38px;

    span {
        background: $black;
        display: block;
        height: 8px;
        transition: all 0.2s $default-transition;
        width: 50%;
    }
}

.title {
    font-size: 2.5em;
    margin-bottom: 20px;

    @media (max-width: $tablet) {
        font-size: 1.5625em;
        line-height: 1.16;
        margin-bottom: 14px;
    }
}

.description {
    font-size: 1.125em;
    font-weight: 500;
    line-height: 1.72;
    max-width: 550px;

    @media (max-width: $tablet) {
        font-size: 1em;
        line-height: 1.75;
    }
}

.fields {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    margin-top: 24px;
}

.notice {
    composes: notice from "../../components/signup-form/styles.module.scss";
}

.button-container {
    composes: button-container from "../../components/signup-form/styles.module.scss";
}

.error {
    composes: error from "../../components/signup-form/styles.module.scss";
    margin-left: 0;
    margin-top: 16px;
}

.back {
    align-items: center;
    color: $black;
    cursor: pointer;
    display: flex;
    font-size: 1.25em;
    font-weight: 900;
    height: 50px;
    justify-content: center;
    margin-right: 28px;
    transition: opacity 0.2s $default-transition;

    @media (max-width: $tablet) {
        font-size: 1.125em;
        height: auto;
        margin-bottom: 16px;
    }

    @media (max-width: $mobile) {
        margin-bottom: 16px;
    }
}

.back svg {
    margin-right: 16px;
    position: relative;
    top: 1px;

    @media (max-width: $tablet) {
        margin-right: 8px;
    }
}

.back:hover {
    opacity: 0.65;
}

.article--success {
    margin-top: 198px;

    @media (max-width: $widescreen) {
        margin-top: 108px;
    }

    @media (max-width: $desktop) {
        margin-top: 0;
    }

    @media (max-width: $tablet) {
        margin-top: 0;
    }
}

.article--success :global(.button) {
    margin-top: 28px;
}
