@import "../../../styles/variables";
@import "../../../styles/utilities/mixins";

.title {
    @include title;
    @include title-spacing;

    composes: section-title from "../../../styles/elements/section.scss";
    font-family: $main-font;
    text-align: center;
    text-transform: uppercase;
}

.item {
    @include bg-white;
    @include grid-column;

    margin-top: 24px;
    padding: 24px;

    blockquote {
        font-size: $text-size-normal;
        line-height: 1.25em;
        position: relative;

        &::before {
            color: $yellow-light;
            content: open-quote;
            font-size: 4em;
            line-height: 0.1em;
            margin-right: 0.25em;
            vertical-align: top;
        }

        &::after {
            color: $yellow-light;
            content: close-quote;
            font-size: 4em;
            line-height: 1em;
            margin-left: 0.25em;
            position: absolute;
            vertical-align: bottom;
        }
    }

    .author {
        @include grid-row;

        align-items: center;
        gap: 12px;
        margin-top: 24px;

        figure {
            border-radius: 48px;
            height: 48px;
            overflow: hidden;
            width: 48px;
        }
    }
}
