@import "../../styles/variables";

.cookie-notice {
    background: $black;
    bottom: 0;
    color: $white;
    left: 0;
    padding: 22px 0;
    position: fixed;
    user-select: none;
    width: 100%;
    z-index: 10;

    @media (max-width: $tablet) {
        bottom: 55px;
    }
}

.wrapper {
    max-width: 1163px;
    padding: 0 22px;

    @media (min-width: $tablet) and (max-width: $widescreen) {
        padding: 0 86px 0 26px;
    }

    @media (max-width: $tablet) {
        padding: 0 64px 0 16px;
    }

    @media (max-width: $mobile) {
        padding: 0 16px;
    }
}

.title {
    font-size: $text-size-medium;
    font-weight: bold;
    margin: 0;

    @media (max-width: $tablet) {
        margin-bottom: 8px;
        padding-right: 56px;
    }
}

.description {
    font-size: 1em;
    letter-spacing: 0;
    line-height: 1.56;
    margin-bottom: 0;
    margin-top: 4px;
}

.close {
    align-items: center;
    background: $yellow;
    color: $black;
    cursor: pointer;
    display: flex;
    height: 64px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 64px;

    @media (max-width: $tablet) {
        height: 48px;
        width: 48px;
    }

    svg {
        height: 22px;
        transition: opacity 0.2s $default-transition;
        width: 22px;

        @media (max-width: $tablet) {
            height: 16px;
            width: 16px;
        }
    }

    &:hover svg {
        opacity: 0.65;
    }
}
