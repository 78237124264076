@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.arrows {
    bottom: 0;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: scaleY(-1);
    z-index: 1;
}

.wrapper {
    max-width: 1400px;

    a {
        color: $white;
    }
}

.filterTitle {
    font-size: clamp(1.25rem, 25vw, 2rem);
    font-weight: bold;
    margin-bottom: 1rem;
}

.filter {
    color: $black;
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    z-index: 9;

    @media (max-width: $tablet) {
        grid-template-columns: 1fr;
    }

    .input {
        height: 78px;

        &::placeholder {
            color: #4cbcc9;
            opacity: 1;
            font-size: $text-size-large;
        }
    }

    .submit {
        background: $blue-light;
        border-color: $blue-light;
        color: $white;
        cursor: pointer;
        outline: 0;
        width: 200px;
    }

    .reset {
        background: transparent;
        border: 0;
        color: $white;
        cursor: pointer;
        display: inline-block;
        justify-self: end;
        outline: 0;
        text-align: right;
        width: min-content;
    }
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;

    @media (max-width: $tablet) {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}

.item {
    background: $white;
    display: flex;
    flex-direction: column;

    .image {
        background-color: $black;
        height: 100%;
        max-height: 266px;
        min-height: 189px;
        overflow: hidden;
        position: relative;
    }

    .background {
        @include bg-image;

        position: absolute;
        inset: 0;
    }

    .heading {
        background: $black;
        color: $yellow-light;
        margin-top: auto;
        padding: 18px 24px;

        span {
            display: block;
            font-size: $text-size-normal;
            font-weight: bold;
            line-height: 0.88;
            max-height: 103px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: uppercase;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        height: 100%;
        max-height: 200px;
        padding: 18px 24px;
    }

    .sub {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .region {
        color: $black;
        font-size: $text-size-small;
        font-weight: 700;
        line-height: 1.5;
    }

    .description {
        color: $dark;
        display: block;
        font-size: $text-size-small;
        height: 58px;
        line-height: 1.2;
        overflow: hidden;
    }

    .more {
        color: $blue-light;
        display: inline-block;
        font-size: $text-size-medium;
        font-weight: bold;
        margin-top: 8px;

        svg {
            margin-left: 12px;
            position: relative;
            top: -1px;
            transition: transform 0.2s $default-transition;
        }
    }

    &:hover {
        transform: translateY(-4px);
    }

    &:hover .more svg {
        transform: translateX(4px);
    }
}

.title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.checklist {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 2rem 0;

    h4:nth-of-type(2) {
        padding-top: 1rem;
    }
}

.offer,
.requirements {
    ul {
        margin-left: 0;

        li {
            display: flex;
            font-size: $text-size-normal;
            line-height: 32px;
        }

        li:before {
            content: url("https://careerrebels.nl/check-purple.svg");
            line-height: 32px;
            padding-right: 1rem;
        }
    }

    p {
        margin: 24px 0;
    }
}

.offer {
    ul {
        li:before {
            content: url("https://careerrebels.nl/check-yellow.svg");
        }
    }
}

.video {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    position: relative;

    @media (max-width: $tablet) {
        grid-template-columns: 1fr;
    }

    .frame {
        height: 338px;
        max-width: 600px;
        overflow: hidden;
        position: relative;

        iframe {
            width: 600px;
            height: 338px;
        }
    }
}

.floating {
    display: flex;
    flex-direction: column;
    font-size: $text-size-small;
    position: fixed;
    right: 20px;
    top: 50%;
    z-index: 3;

    div,
    a {
        margin-top: 10px;
        width: 100%;
    }

    @media (max-width: $mobile) {
        display: none;
    }
}

.floating--button {
    box-shadow: -5px 5px 0 rgba(0, 0, 0, 1);
}

.floating--link {
    color: $blue-light;
    padding-top: 8px;
    text-align: right;
    transform: translateX(0);
    transition: transform 0.2s $default-transition;

    &:hover {
        transform: translateX(-8px);
    }
}

.article {
    ul {
        list-style: disc;
        margin: 1rem;

        li {
            font-size: $text-size-normal;
        }
    }

    p {
        margin-bottom: 1rem;
    }
}
