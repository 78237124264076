@import "../../styles/variables";

.sidebar {
    border-right: 1px solid $black;
    color: $black;
    margin-right: 64px;
    width: 294px;

    @media (max-width: $desktop) {
        border-right: 0;
    }
}
