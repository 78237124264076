@import "../../styles/variables";

.hero {
    background-color: $purple;
    background-position: right bottom;
    background-repeat: no-repeat;

    @media (max-width: $mobile) {
        background-image: none !important;
    }
}

.wrapper {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;

    @media (max-width: $desktop) {
        align-items: flex-start;
        flex-direction: column;
    }
}

.content {
    align-self: flex-start;
    color: $white;
    max-width: 1024px;
    min-width: 630px;
    padding-bottom: 48px;
    padding-top: 84px;

    @media (max-width: $desktop) {
        max-width: 100%;
        min-width: 320px;
        padding-bottom: 24px;
        padding-top: 24px;
        width: 100%;
    }

    @media (max-width: $mobile) {
        font-size: $text-size-small;
        max-width: 100%;
        min-width: 300px;
        padding: 12px 0;
    }
}

.title {
    color: $yellow-lighter;
    font-size: 4em;
    margin: 0;

    @media (max-width: $tablet) {
        font-size: 2em;
    }
}

.description {
    font-size: 1.25em;
    margin-top: 20px;
    max-width: 300%;

    h2,
    p {
        margin-bottom: 24px;
    }
}

.image {
    height: auto;
    margin-right: -126px;
    max-width: 910px;
    position: relative;
    width: calc(100% - 360px - 96px + 126px);
    z-index: 2;

    @media (max-width: 1280px) {
        margin-right: 0;
        width: calc(100% - 430px - 32px);
    }

    @media (max-width: 1240px) {
        margin-right: 32px;
        width: calc(100% - 320px - 32px - 32px);
    }

    @media (max-width: 1024px) {
        margin-right: 0;
        width: calc(100% - 320px - 32px);
    }

    @media (max-width: 920px) {
        margin-right: 0;
        max-width: 744px;
        width: 100%;
    }

    @media (max-width: 520px) {
        left: -16px;
        position: relative;
        width: calc(100% + 32px);
    }
}
