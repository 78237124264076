@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.layout {
    @include grid-column;

    background-color: $purple;
    height: 100%;
    padding: 104px 0 0;

    @media (max-width: $widescreen) {
        padding: 44px 0 0;
    }
}

.wrapper {
    @include grid-column;

    height: auto;
    padding-bottom: 56px;
}

.vacancies {
    margin-bottom: 40px;
    margin-top: 24px;
    min-height: 423px;
}

.left {
    display: flex;
    flex-direction: column;
    padding-bottom: 104px;
    position: relative;
    width: calc(100% - 422px);
    z-index: 2;

    @media (max-width: $widescreen) {
        padding-bottom: 0;
        width: 100%;
    }
}

.header {
    margin-bottom: 56px;
    text-align: center;
    width: 100%;

    @media (max-width: $tablet) {
        margin-bottom: 24px;
    }

    h1 {
        color: $yellow-light;
        font-family: $title-font;
        font-size: 4.375em;
        font-weight: bold;
        line-height: 0.8;
        margin-bottom: 24px;
        margin-top: 0;
        text-transform: uppercase;

        @media (max-width: $tablet) {
            font-size: 3em;
            line-height: 1;
        }
    }

    p {
        color: $white;
        font-size: $text-size-normal;
        line-height: 1.2;
    }
}

.content {
    color: $white;
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (max-width: $tablet) {
        flex-flow: column;
    }

    .text {
        font-size: 1.125em;
        line-height: 1.72;
        margin-right: 64px;
        max-width: 352px;
        min-width: 352px;

        @media (max-width: $desktop) {
            min-width: 1px;
        }

        @media (max-width: $tablet) {
            max-width: 100%;
            min-width: 100%;
        }

        &:last-of-type {
            margin-right: 0;
        }

        p {
            margin-bottom: 42px;
        }

        .link {
            color: $white;
            font-weight: 900;
            margin-left: 6px;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .text :global(.button) :global(.content) {
        font-size: 1em;
    }
}

.image {
    align-items: flex-start;
    display: flex;
    position: relative;
    width: 422px;

    @media (max-width: $widescreen) {
        margin-top: 32px;
        width: 100%;
    }

    @media (max-width: $tablet) {
        flex-flow: column;
    }
}

.image img {
    bottom: 0;
    left: -24px;
    min-width: 800px;
    position: absolute;

    @media (max-width: $widescreen) {
        max-width: 800px;
        position: static;
    }

    @media (max-width: $desktop) {
        max-width: 100%;
        min-width: 1px;
    }

    @media (max-width: $tablet) {
        margin-top: -32px;
    }
}

.image svg {
    align-self: flex-end;
    bottom: 132px;
    display: flex;
    height: auto;
    left: 0;
    max-width: 529px;
    position: absolute;
    width: 529px;
    z-index: 2;

    @media (max-width: $widescreen) {
        bottom: 64px;
    }

    @media (max-width: $tablet) {
        align-self: flex-start;
        position: static;
        width: 100%;
    }
}
