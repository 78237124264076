@import "../../styles/variables";

.input-container {
    display: flex;
    flex-flow: column;
    margin-bottom: 32px;
    width: 100%;

    @media (max-width: $mobile) {
        margin-bottom: 16px;
    }
}

.input-container__label {
    font-size: 1.125em;
    font-weight: bold;
    margin-bottom: 8px;

    @media (max-width: $tablet) {
        font-size: 1em;
    }
}

.input-container--half {
    margin-right: 68px;
    width: calc(50% - 34px);

    @media (max-width: 620px) {
        margin-right: 0;
        width: 100%;
    }

    &:nth-child(2n + 2) {
        margin-right: 0;
    }
}

.input-container--error input,
.input-container--success input:focus {
    border-color: $red;
    outline-color: $red;
}

.input-container--success input,
.input-container--success input:focus {
    border-color: $green;
    outline-color: $green;
}

.select {
    align-items: center;
    background: $white;
    display: flex;
    height: 50px;
    justify-content: space-between;
    padding-left: 11px;
    padding-right: 11px;
    position: relative;
    width: 100%;

    span {
        font-size: 1.125em;
        font-weight: bold;

        @media (max-width: $tablet) {
            font-size: 1em;
        }
    }

    select {
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    svg {
        color: $blue-light;
        height: 23px;
        width: 14px;
    }
}

.required:after {
    content: "*";
    color: $red;
    font-weight: 100;
    font-size: 1rem;
}
