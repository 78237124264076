@import "../../styles/variables";

.footer {
    background: $black;
    color: $white;
    padding-bottom: 80px;
    padding-top: 80px;

    @media (max-width: $tablet) {
        font-size: 1.25em;
        padding-bottom: 48px;
        padding-top: 54px;
    }

    a {
        color: $white;
    }
}

.footer-column {
    display: flex;

    @media (max-width: $widescreen) {
        div {
            min-width: 100px;
        }
    }

    @media (max-width: $tablet) {
        flex-flow: column;
    }

    &:last-of-type div {
        @media (max-width: $tablet) {
            display: none;
        }

        &:nth-child(1) {
            @media (max-width: $tablet) {
                display: flex;
            }
        }
    }
}

.footer-column--left {
    margin-right: 48px;

    @media (max-width: $desktop) {
        margin-right: 24px;
    }

    @media (max-width: $tablet) {
        flex-flow: row;
        justify-content: flex-start;
        margin-bottom: 54px;
    }

    @media (max-width: $mobile) {
        justify-content: space-between;
    }
}

.logo-mobile {
    display: none;
    margin-bottom: 72px;

    @media (max-width: $tablet) {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;

        svg {
            height: auto;
            max-width: 208px;
        }
    }
}

.brand {
    margin-right: 68px;

    @media (max-width: $widescreen) {
        margin-right: 42px;
        max-width: 186px;
    }

    @media (max-width: $desktop) {
        margin-right: 24px;
    }

    @media (max-width: $tablet) {
        display: block;
        margin-left: 68px;
        margin-right: 0;
        order: 2;
        width: 30px;
    }

    @media (max-width: $mobile) {
        margin-left: 0;
    }
}

.logo {
    margin: 8px 0 40px;

    @media (max-width: $desktop) {
        margin-left: 0;
    }

    svg {
        height: auto;
        width: 208px;

        @media (max-width: $desktop) {
            width: 116px;
        }
    }

    @media (max-width: $tablet) {
        display: none;
    }
}

.wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: $tablet) {
        flex-flow: column;
    }
}

.social {
    display: flex;
    justify-content: flex-start;

    @media (max-width: $widescreen) {
        flex: 1;
        flex-wrap: wrap;
    }

    @media (max-width: $mobile) {
        flex-flow: column;
    }
}

.social li {
    margin-right: 16px;

    @media (max-width: $widescreen) {
        margin-bottom: 16px;
    }

    @media (max-width: $mobile) {
        margin-bottom: 22px;
        margin-right: 0;
    }

    &:last-of-type {
        margin-right: 0;
    }
}

.social li a {
    color: $white;
    display: block;
    transition: opacity 0.2s $default-transition;

    @media (max-width: $desktop) {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    svg {
        height: 38px;
        transition: opacity 0.2s $default-transition,
            transform 0.2s $default-transition;
        width: 38px;

        @media (max-width: $desktop) {
            max-height: 30px;
            max-width: 30px;
        }
    }
}

.social li a:hover {
    opacity: 0.65;
}

.social a:hover svg {
    opacity: 0.65;
    transform: translateY(-2px);
}

.footer-column--locations {
    flex: 1;
    flex-wrap: wrap;
    margin-right: -24px;

    @media (max-width: $desktop) {
        margin-right: 0;
    }
}

.social-title {
    display: block;
    font-size: $text-size-large;
    margin-bottom: 12px;

    @media (max-width: $tablet) {
        display: none;
    }
}

.kiwa {
    margin-top: 20px;
}
