@import "../variables";
@import "../utilities/mixins";

.columns {
    @include grid-row;

    flex-wrap: wrap;

    @media (max-width: $tablet) {
        flex-direction: column;
    }
}

@for $i from 1 through 12 {
    .column-#{$i} {
        @include grid-column;

        width: calc(100% / 12) * $i;

        @media (max-width: $tablet) {
            flex-direction: column;
            width: 100%;
        }

        &.has-text-centered {
            align-items: center;
        }
    }
}
