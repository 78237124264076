@import "../../../styles/variables";
@import "../../../styles/utilities/mixins";

.hero {
    overflow: hidden;
    padding-top: 56px;
    position: relative;

    @media (max-width: $mobile) {
        padding-top: 24px;
    }
}

.arrows {
    left: 0;
    pointer-events: none;
    position: absolute;
    top: -50px;
    transform: scaleY(-1);
}

.wrapper {
    display: grid;
    grid-column-gap: 56px;
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: -56px;

    @media (max-width: $desktop) {
        grid-column-gap: 0;
        margin-bottom: 0;
    }
}

.content {
    background: transparent;
    grid-column: 1 / 4;

    @media (max-width: $mobile) {
        grid-column: 1 / -1;
    }

    .title {
        text-transform: uppercase;
        word-break: keep-all;
        color: $white;
    }

    .description {
        color: $white;
        font-size: $text-size-normal;
        margin-top: 1rem;
    }

    :global(.button) {
        margin-top: 56px;

        @media (max-width: $desktop) {
            margin: 24px 0;
        }
    }
}

.image {
    @include grid-row;
    @include hide-on-mobile;

    bottom: 0;
    grid-column: 4 / 6;
    justify-content: center;
    //margin-top: -24px;
    position: relative;

    img {
        max-height: 400px;
        object-fit: cover;

        @media (max-width: $tablet) {
            max-height: 300px;
        }
    }
}
