@import "../../../styles/variables";

.hero {
    max-height: 500px;

    .figure {
        background: no-repeat center;
        height: 100%;
        object-fit: cover;

        img {
            width: 100%;
        }

        @media (max-width: $mobile) {
            height: auto !important;
        }
    }

    @media (max-width: $mobile) {
        height: auto;
        max-height: 300px;
    }
}
