@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.text {
    background: $yellow;
    display: flex;
    overflow: hidden;
    position: relative;
}

.wrapper {
    align-items: flex-end;
    display: flex;
    height: auto;
    justify-content: space-between;
    position: relative;

    @media (max-width: $desktop) {
        align-items: flex-start;
        flex-flow: column-reverse;
    }
}

.arrows {
    height: 477px;
    left: 126px;
    position: absolute;
    top: -80px;
    transform: rotate(180deg);
    z-index: 1;

    @media (max-width: $widescreen) {
        left: 0;
    }

    @media (max-width: $desktop) {
        top: -420px;
    }

    @media (max-width: $tablet) {
        height: 440px;
        left: -16px;
        top: -380px;
        width: calc(100% + 32px);
    }

    @media (max-width: $mobile) {
        width: auto;
    }
}

.figure {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: center;
    margin-right: 56px;
    max-width: 600px;
    position: relative;
    width: 100%;
    z-index: 2;
}

:global(.ie) .figure {
    height: 794px;
}

.figure img {
    width: 100%;
}

.content {
    color: currentColor;
    margin-bottom: 96px;
    padding-top: 104px;
    position: relative;
    width: calc(100% - 574px - 56px);
    z-index: 2;

    @media (max-width: $widescreen) {
        width: calc(100% - 320px - 56px);
    }

    @media (max-width: $desktop) {
        width: calc(100% - 320px - 32px);
    }

    @media (max-width: 920px) {
        margin-bottom: 32px;
        max-width: 660px;
        padding-top: 54px;
        width: 100%;
    }
}

.logo {
    margin-bottom: 20px;
    margin-left: -80px;
    width: 100%;

    @media (max-width: 920px) {
        margin-bottom: 42px;
        margin-left: 0;
        max-width: 504px;
    }

    svg {
        height: auto;
        width: 90%;
    }
}

.title {
    color: $white;
    font-size: 4.375em;
    line-height: 1.07;
    margin-bottom: 54px;
    margin-top: 0;

    @media (max-width: $desktop) {
        font-size: 2.1875em;
        line-height: 1.09;
        margin-bottom: 18px;
    }
}

.description {
    margin-right: -126px;

    @media (max-width: 1510px) {
        margin-right: 0;
    }

    h2 {
        font-size: 2.5em;
        line-height: 1.2;
        margin-bottom: 32px;

        @media (max-width: $desktop) {
            font-size: $text-size-large;
            line-height: 1.16;
            margin-bottom: 14px;
        }
    }
}

.description :global(.loading) {
    display: inline-block;
    margin-left: 25px;
    width: auto;
}

.text--yellow {
    @include bg-yellow;
}

.text--yellow-light {
    @include bg-yellow-light;
}

.text--purple {
    @include bg-purple;
}

.text--blue {
    @include bg-blue;
}

.text--blue-light {
    @include bg-blue-light;
}

.text--red {
    @include bg-red;
}

.text--green {
    @include bg-green;
}

.text--light {
    @include bg-light;
}

.text--white {
    @include bg-white;
}

.text--black {
    @include bg-black;
}
