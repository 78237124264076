@import "../../../styles/variables";
@import "../../../styles/utilities/mixins";

.wrapper {
    @include grid-column;

    align-items: center;
}

.title {
    @include title;
    @include title-spacing;

    font-family: $main-font;
    text-transform: uppercase;
}

.video {
    height: auto;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;

    iframe,
    object,
    embed {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
