@import "../variables";

//* {
//    outline: 1px solid #f00 !important;
//}

body {
    cursor: default;
    min-height: 100vh;
    min-width: 320px;
    overflow-x: hidden;
    position: relative;
    scroll-behavior: smooth;
}

main {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    margin-top: 80px;
    min-height: 100%;
    width: 100%;

    @media screen and (max-width: $desktop) {
        margin-top: 60px;
    }

    @media screen and (max-width: $mobile) {
        margin-top: 40px;
    }
}

.wrapper {
    margin: 0 auto;
    max-width: 1200px;
    width: calc(100% - 32px);

    @media screen and (min-width: $desktop) {
        width: calc(100% - 100px);
    }

    &.has-content-centered {
        align-items: center;
    }
}

.wrapper--content {
    max-width: 960px;
}

.wrapper--large {
    max-width: 1440px;

    @media screen and (min-width: $desktop) {
        width: 100%;
    }
}

.disable-scrolling {
    overflow: hidden;
}

.cr {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
