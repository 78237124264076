@charset "UTF-8";

@import-normalize;
@import-sanitize;

@import "variables";
@import "fonts/fonts";
@import "base/additional";
@import "base/typography";
@import "base/grid";
@import "elements/button";
@import "elements/section";
@import "components/columns";
@import "components/swiper";
@import "components/video";
@import "forms/helpers";
@import "forms/controls";
@import "forms/range";
@import "tailwind";
@import "recruitnow";

.simplebar-scrollbar {
    width: 8px;
}

.simplebar-scrollbar::before {
    border-radius: 0;
    opacity: 1;
}

.simplebar-track .simplebar-scrollbar.simplebar-visible::before {
    opacity: 1;
}

.wizard {
    background: $blue;
    min-height: 500px;
    position: relative;
}

.full-width {
    width: 100%;
}

.notice {
    @include bg-purple;

    margin: 12px 0;
    padding: 10px 20px;
    width: fit-content;
}

.swiper-slide a {
    margin: 0;
}

ul {
    list-style: none;
}

::placeholder {
    color: #808080;
    opacity: 0.5;
}

a {
    cursor: pointer;
}

.toggle {
    display: none;
}

.wrap-collabsible {
    margin: 1.2rem 0;
}
.lbl-toggle {
    display: block;
    font-weight: bold;
    font-family: monospace;
    font-size: 1.2rem;
    text-transform: uppercase;
    text-align: center;
    padding: 1rem;
    color: #ddd;
    background: #0069ff;
    cursor: pointer;
    border-radius: 7px;
    transition: all 0.25s ease-out;
    z-index: 99;
}
.lbl-toggle:hover {
    color: #fff;
}
.lbl-toggle::before {
    content: " ";
    display: inline-block;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    vertical-align: middle;
    margin-right: 0.7rem;
    transform: translateY(-2px);
    transition: transform 0.2s ease-out;
}
.toggle:checked + .lbl-toggle::before {
    transform: rotate(90deg) translateX(-3px);
}
.collapsible-content {
    max-height: 100px;
    overflow: hidden;
    transition: max-height 0.25s ease-in-out;
}
.toggle:checked + .lbl-toggle + .collapsible-content {
    max-height: 350px;
}
.toggle:checked + .lbl-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.collapsible-content .content-inner {
    background: rgba(0, 105, 255, 0.2);
    border-bottom: 1px solid rgba(0, 105, 255, 0.45);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: 0.5rem 1rem;
}
.collapsible-content p {
    margin-bottom: 0;
}

.recruiter-slide:not(.swiper-slide-active) img {
    transition: all 0.5s $default-transition;
    transform: scale(0.75);
}

.list-checkmark {
    margin-left: 0;

    li {
        display: flex;
        font-size: $text-size-normal;
        line-height: 32px;
    }

    li:before {
        content: url("https://careerrebels.nl/check-yellow-light.svg");
        line-height: 32px;
        padding-right: 1rem;
    }
}

.list-chevron {
    margin-left: 0;

    li {
        display: flex;
        font-size: $text-size-normal;
        line-height: 32px;
    }

    li:before {
        content: url("https://careerrebels.nl/chevron-yellow-light.svg");
        line-height: 32px;
        padding-right: 1rem;
    }

    &.markers-purple {
        li:before {
            content: url("https://careerrebels.nl/chevron-purple.svg");
        }
    }

    &.markers-yellow {
        li:before {
            content: url("https://careerrebels.nl/chevron-yellow.svg");
        }
    }
}

.simplebar-content {
    overflow: scroll !important;
    height: 100% !important;

    ul {
        margin: 0 !important;
    }
}
