@import "../variables";

@mixin bg-white {
    background-color: $white;
    color: $black;
}

@mixin bg-light {
    background-color: $light;
}

@mixin bg-yellow {
    background-color: $yellow;
}

@mixin bg-yellow-light {
    background-color: $yellow-light;
}

@mixin bg-purple {
    background-color: $purple;
    color: $white;
}

@mixin bg-blue {
    background-color: $blue;
    color: $white;
}

@mixin bg-blue-light {
    background-color: $blue-light;
}

@mixin bg-red {
    background-color: $red;
    color: $white;
}

@mixin bg-green {
    background-color: $green;
}

@mixin bg-black {
    background: $black;
    color: $white;
}

@mixin bg-image {
    background-color: $black;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@mixin grid-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

@mixin grid-column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

@mixin aspect-ratio($width, $height) {
    position: relative;

    &::before {
        content: "";
        display: block;
        padding-top: ($height / $width) * 100%;
        width: 100%;
    }

    > .content {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}

@mixin icon {
    flex-shrink: 0;
    height: 32px;
    width: 32px;

    @media (max-width: $mobile) {
        height: 24px;
        width: 24px;
    }
}

@mixin title {
    font-family: $title-font;
    font-size: $title-size-desktop;

    @media (max-width: $tablet) {
        font-size: $title-size-tablet;
    }

    @media (max-width: $mobile) {
        font-size: $title-size-mobile;
    }
}

@mixin title-spacing {
    margin-bottom: 24px;

    @media (max-width: $tablet) {
        margin-bottom: 12px;
    }
}

@mixin hide-on-mobile {
    @media (max-width: $mobile) {
        display: none;
    }
}

@mixin font {
    font-size: clamp(0.75rem, 1rem, 5vw);
}

@mixin wrapper {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
}

@mixin button {
    background: none;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-size: $text-size-medium;
    font-weight: bold;
    height: 50px;
    max-width: 320px;
    padding: 0;
    position: relative;

    @media (max-width: $mobile) {
        max-width: 100%;
    }

    &:focus {
        outline: 0;
    }

    svg {
        fill: currentColor;
        flex-shrink: 0;
        height: 32px;
        margin-left: 22px;
        position: relative;
        transform: translateX(0);
        transition: transform 0.2s $default-transition;
        width: 32px;
    }

    &:hover {
        svg {
            transform: translateX(4px);
        }
    }
}

@mixin button-blue {
    @include bg-blue-light;

    color: $white;
    padding: 5px 31px 10px;

    &:hover,
    &:active {
        background: $blue-lighter;
    }
}
