@import "../../styles/variables";

.wrapper {
    overflow: hidden;
}

.content {
    line-height: 27px;
    transition: height 0.25s ease-out;
    overflow: hidden;

    &.closed {
        height: 108px;
        max-height: 108px;
    }

    &.open {
        height: 100%;
        max-height: 100%;
    }
}

.expand {
    background: $light;
    display: block;
    font-size: $text-size-normal;
    font-weight: bold;
    line-height: 1.5;
    margin-top: 4px;
    z-index: 9;
}
