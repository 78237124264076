@font-face {
    font-display: swap;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 600;
    src:
        url("space-grotesk/SpaceGrotesk-SemiBold.woff2") format("woff2"),
        url("space-grotesk/SpaceGrotesk-SemiBold.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: bold;
    src:
        url("space-grotesk/SpaceGrotesk-Bold.woff2") format("woff2"),
        url("space-grotesk/SpaceGrotesk-Bold.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: normal;
    src:
        url("space-grotesk/SpaceGrotesk-Regular.woff2") format("woff2"),
        url("space-grotesk/SpaceGrotesk-Regular.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 500;
    src:
        url("space-grotesk/SpaceGrotesk-Medium.woff2") format("woff2"),
        url("space-grotesk/SpaceGrotesk-Medium.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    src:
        url("space-grotesk/SpaceGrotesk-Light.woff2") format("woff2"),
        url("space-grotesk/SpaceGrotesk-Light.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 500;
    src:
        url("space-grotesk/SpaceGrotesk-Medium.woff2") format("woff2"),
        url("space-grotesk/SpaceGrotesk-Medium.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: bold;
    src:
        url("space-grotesk/SpaceGrotesk-Bold.woff2") format("woff2"),
        url("space-grotesk/SpaceGrotesk-Bold.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 600;
    src:
        url("space-grotesk/SpaceGrotesk-SemiBold.woff2") format("woff2"),
        url("space-grotesk/SpaceGrotesk-SemiBold.woff") format("woff");
}

@font-face {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 300;
    src:
        url("space-grotesk/SpaceGrotesk-Light.woff2") format("woff2"),
        url("space-grotesk/SpaceGrotesk-Light.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: normal;
    src:
        url("space-grotesk/SpaceGrotesk-Regular.woff2") format("woff2"),
        url("space-grotesk/SpaceGrotesk-Regular.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Stolzl Display";
    font-style: normal;
    font-weight: normal;
    src:
        url("stolzl-display/StolzlDisplay-Regular.woff2") format("woff2"),
        url("stolzl-display/StolzlDisplay-Regular.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Stolzl Display";
    font-style: normal;
    font-weight: bold;
    src:
        url("stolzl-display/StolzlDisplay-Bold.woff2") format("woff2"),
        url("stolzl-display/StolzlDisplay-Bold.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Stolzl Display";
    font-style: normal;
    font-weight: 500;
    src:
        url("stolzl-display/StolzlDisplay-Medium.woff2") format("woff2"),
        url("stolzl-display/StolzlDisplay-Medium.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Stolzl Display";
    font-style: normal;
    font-weight: 300;
    src:
        url("stolzl-display/StolzlDisplay-Light.woff2") format("woff2"),
        url("stolzl-display/StolzlDisplay-Light.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Stolzl Display Normal";
    font-style: normal;
    font-weight: normal;
    src:
        url("stolzl-display/StolzlDisplay-Normal.woff2") format("woff2"),
        url("stolzl-display/StolzlDisplay-Normal.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Stolzl Display";
    font-style: normal;
    font-weight: 100;
    src:
        url("stolzl-display/StolzlDisplay-Thin.woff2") format("woff2"),
        url("stolzl-display/StolzlDisplay-Thin.woff") format("woff");
}
