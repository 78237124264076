@import "../../styles/variables";

.loading {
    align-items: center;
    display: block;
    height: 50px;
    justify-items: center;
    position: relative;
    width: 100vw;
}

.spinner {
    height: 50px;
    left: 50%;
    margin: -25px 0 0 -25px;
    position: absolute;
    top: 50%;
    width: 50px;
    z-index: 2;

    //:global {
    animation: rotate 2s linear infinite;
    //}

    circle {
        stroke: $white;
        stroke-linecap: round;

        //:global {
        animation: dash 1.5s ease-in-out infinite;
        //}
    }
}

.spinner-ie,
.spinner-ie::after {
    border-radius: 50%;
    height: 47px;
    width: 47px;
}

.spinner-ie {
    border-bottom: 8px solid rgba(255, 255, 255, 0.2);
    border-left: 8px solid currentColor;
    border-right: 8px solid rgba(255, 255, 255, 0.2);
    border-top: 8px solid rgba(255, 255, 255, 0.2);
    margin: 0 auto;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);

    :global {
        animation: spin 1.1s infinite linear;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}
