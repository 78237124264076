@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.author {
    @include grid-row;

    align-items: flex-end;
    font-size: $text-size-normal;
    height: 100%;

    //@media (max-width: $tablet) {
    //    margin-top: 24px;
    //}
}

.contact {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 50%;

    @media (max-width: $tablet) {
        width: 100%;
    }

    .detail {
        @include grid-row;

        align-items: center;
        padding: 8px 0;
    }

    a {
        color: currentColor;
    }

    svg {
        color: currentColor;
        flex-shrink: 0;
        height: 24px;
        margin-right: 12px;
        width: 24px;
    }

    :global(.button svg) {
        height: 32px;
        width: 32px;
    }
}

.figure {
    width: 50%;

    @media (max-width: $tablet) {
        display: none;
    }
}

.image {
    margin-bottom: -56px;
    width: 100%;
}
