@import "../../styles/variables";

.item {
    align-items: center;
    color: $yellow;
    cursor: pointer;
    display: flex;
    font-family: $main-font;
    font-size: $text-size-medium;
    font-weight: bold;
    height: 100%;
    justify-content: center;
    padding: 0 20px;
    text-decoration: none;

    &.purple {
        color: $purple;
    }

    @media (max-width: $widescreen) {
        padding: 0 24px;
    }

    @media (max-width: $desktop) {
        display: none;
    }
}

.vacancies svg {
    opacity: 0;
    position: relative;
    top: 2px;
    transform: translateX(4px);
    transition: all 0.2s $default-transition;
    width: 0;
}

.vacancies:hover svg {
    margin-left: 12px;
    opacity: 1;
    transform: translateX(0);
    width: 38px;
}
