@import "../../../styles/variables";
@import "../../../styles/utilities/mixins";

.block {
    display: flex;
    flex-direction: column;
    position: relative;
}

.grid {
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: $tablet) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $mobile) {
        grid-template-columns: 1fr;
    }
}
