@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.wrapper {
    @include grid-column;
}

.section--top {
    @include grid-column;
}

.section--fold {
    padding-top: 56px !important;

    @media (max-width: $mobile) {
        padding-top: 0 !important;
    }
}

.section--overlap {
    @media (max-width: $tablet) {
        padding-top: 0;
    }
}

.section--padding {
    padding-bottom: 56px !important;

    @media (max-width: $mobile) {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }
}

.button-group {
    @include grid-row;

    justify-content: flex-end;

    @media (max-width: $mobile) {
        flex-direction: column-reverse;
        margin-top: 24px;
    }
}

.hobby--list {
    @include grid-column;

    .hobby--list-item {
        margin-top: 12px;

        svg {
            flex-shrink: 0;
            margin-right: 12px;
        }
    }
}

.top {
    background: linear-gradient(180deg, $black 65%, $yellow 65%) !important;
    display: flex;
    flex-direction: column;

    :global(.section) {
        padding: 0;
    }

    @media (max-width: $tablet) {
        flex-direction: column-reverse;
    }

    @media (max-width: $mobile) {
        padding-top: 0 !important;

        :global(.section) {
            padding-top: 24px;
        }
    }
}

.introduction {
    @include grid-column;

    @media (max-width: $tablet) {
        flex-direction: column-reverse;
    }
}

.panel {
    &--active {
        max-height: 3em;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
    }
}

.readmore {
    display: none;

    @media (max-width: $tablet) {
        color: $purple;
        display: inline-block;
        font-style: italic;
        margin-bottom: 24px;
    }
}

.section--title {
    font-family: $title-font;
    font-size: $title-size-desktop;
    margin-bottom: 24px;

    @media (max-width: $tablet) {
        font-size: $title-size-tablet;
    }

    @media (max-width: $mobile) {
        font-size: $title-size-mobile;
    }
}

.description {
    margin-bottom: 24px;

    & > p {
        font-size: $text-size-normal;
        margin-bottom: 12px;
    }

    @media (max-width: $mobile) {
        font-size: $text-size-small;
    }
}

.image {
    background-color: $black;
    height: 100%;
    //min-height: 400px;
    max-height: 600px;
    overflow: hidden;
    position: relative;
    width: 100%;

    @media (max-width: $tablet) {
        min-height: 200px;
        //margin: 0 -16px;
        width: 100vw;
    }
}

.background {
    background-color: $black;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.heading {
    background-color: $black;
    color: $blue-light;
    margin-top: auto;
    padding: 18px 24px;
    text-align: center;

    @media (max-width: $tablet) {
        margin-bottom: -16px;
        margin-left: -16px;
        width: 100vw;
    }

    @media (max-width: $mobile) {
        padding: 8px 24px;
    }

    span {
        display: block;
        font-size: $title-size-desktop;
        font-weight: bold;
        max-height: 103px;
        overflow: hidden;

        @media (max-width: $mobile) {
            font-size: $title-size-mobile;
        }
    }

    i {
        color: $white;
        display: block;
        padding: 12px 0;
    }
}

.hero {
    //margin-top: -200px;

    /* @media (max-width: $tablet) {
      margin-top: 0;
      padding-bottom: 84px;
    } */
}

.row {
    @include grid-row;
}

.hobbys {
    @include grid-row;

    justify-content: center;

    @media (max-width: $desktop) {
        width: 100%;
    }

    .list {
        @include grid-column;

        align-self: flex-end;
        font-size: $text-size-normal;

        @media (max-width: $tablet) {
            margin-bottom: 24px;
        }

        @media (max-width: $mobile) {
            margin-bottom: 12px;
        }
    }

    .list--item {
        @include grid-row;

        align-items: center;
        font-size: $text-size-normal;
        padding: 12px 12px 12px 0;

        @media (max-width: $mobile) {
            padding-bottom: 12px;
        }

        svg {
            flex-shrink: 0;
            height: 32px;
            margin-right: 16px;
            width: 32px;

            @media (max-width: $mobile) {
                height: 24px;
                margin-right: 12px;
                width: 24px;
            }
        }
    }
}

.skills {
    @include grid-row;

    justify-content: center;
    margin-bottom: 24px;

    :global(.columns) {
        width: 80%;

        @media (max-width: $tablet) {
            width: 100%;
        }
    }

    @media (max-width: $desktop) {
        width: 100%;
    }

    .list {
        @include grid-column;

        align-self: center;
        font-size: $text-size-normal;

        @media (max-width: $tablet) {
            margin-bottom: 24px;
        }

        @media (max-width: $mobile) {
            margin-bottom: 12px;
        }
    }

    .list--item {
        @include grid-row;

        align-items: center;
        font-size: $text-size-normal;
        padding: 12px 0;

        svg {
            flex-shrink: 0;
            height: 32px;
            margin-right: 16px;
            width: 32px;

            @media (max-width: $mobile) {
                height: 24px;
                margin-right: 12px;
                width: 24px;
            }
        }
    }
}

.choose--title {
    font-size: $title-size-desktop;

    @media (max-width: $tablet) {
        font-size: $title-size-tablet;
        text-align: center;
    }

    @media (max-width: $mobile) {
        color: $white;
        font-size: $title-size-mobile;
        padding: 24px 16px;

        br {
            display: none;
        }
    }
}

.item--missed {
    composes: closed from "../vacancy/styles.module.scss";
}

.afwezig {
    composes: closed from "../vacancy/styles.module.scss";
    transform: rotate(-5deg) !important;

    @media (max-width: $mobile) {
        transform: rotate(-5deg) !important;
    }
}

.buttons {
    composes: buttons from "../ui/pages/styles.module.scss";

    @media (max-width: $mobile) {
        margin-bottom: 24px !important;
        margin-top: 24px !important;
    }

    &--centered {
        composes: buttons--centered from "../ui/pages/styles.module.scss";
        margin-bottom: 0 !important;
        margin-top: 0 !important;

        @media (max-width: $mobile) {
            flex-direction: column;

            h3 {
                padding-bottom: 24px;
            }
        }
    }
}

.skillList {
    @include grid-row;

    justify-content: center;

    @media (max-width: $mobile) {
        flex-direction: column-reverse;
    }
}

.skillColumn {
    max-width: 300px;
    min-width: 300px;
    word-wrap: break-word;
}
