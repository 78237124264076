.oe_widget {
    max-width: 600px;
}

.oe_title {
    margin-bottom: 2rem;
}

.oe_textElement {
    width: 100%;

    .oe_label {
        display: none;
    }
}

.oe_widget input:not([type="file"]),
.oe_widget textarea {
    box-sizing: border-box;
    font-size: 100%;
    margin-bottom: 30px;
    padding: 7px;
    transition: 500ms;
}

/*.oe_widget input:not([type="file"]):hover,
.oe_widget textarea:hover {
    border-color: black;
}
.oe_widget input:not([type="file"]):focus,
.oe_widget textarea:focus {
    border-color: blue;
}*/

.oe_widget input[type="text"],
.oe_widget input[type="email"],
.oe_widget input[type="tel"],
.oe_widget input[type="number"],
.oe_widget input[type="search"],
.oe_widget textarea {
    width: 100%;
}

.oe_widget textarea {
    min-height: 150px;
    resize: vertical;
}

.oe_widget button {
    background-color: gray;
    border: 1px solid gray;
    border: 0;
    color: white;
    cursor: pointer;
    font-size: 100%;
    margin-bottom: 30px;
    outline: 0;
    padding: 14px 28px;
    transition: 500ms;
}
.oe_widget button:hover {
    background-color: black;
    border-color: black;
}
.oe_widget button:focus {
    background-color: lightgray;
    border-color: lightgray;
}

.oe_widget .oe_radioListElement,
.oe_widget .oe_checkboxListElement {
    position: relative;
}
.oe_widget .oe_radioListElement input,
.oe_widget .oe_checkboxListElement input {
    height: 25px;
    margin: 0;
    opacity: 0;
    position: absolute;
    width: 25px;
}
.oe_widget .oe_radioListElement .oe_label,
.oe_widget .oe_checkboxListElement .oe_label {
    cursor: pointer;
    display: inline-block;
    line-height: 25px;
    margin-bottom: 30px;
    padding: 0 10px 0 32px;
    position: relative;
}
.oe_widget .oe_radioListElement .oe_label:before,
.oe_widget .oe_radioListElement .oe_label:after,
.oe_widget .oe_checkboxListElement .oe_label:before,
.oe_widget .oe_checkboxListElement .oe_label:after {
    content: "";
    position: absolute;
    transition: 500ms;
}
.oe_widget .oe_radioListElement .oe_label:before,
.oe_widget .oe_checkboxListElement .oe_label:before {
    background-color: #ffffff;
    border: 1px solid gray;
    height: 23px;
    left: 0;
    top: 0;
    width: 23px;
}
.oe_widget .oe_radioListElement .oe_label:after,
.oe_widget .oe_checkboxListElement .oe_label:after {
    background-color: #000;
    height: 17px;
    left: 4px;
    opacity: 0;
    top: 4px;
    width: 17px;
}
.oe_widget .oe_radioListElement .oe_label:hover:before,
.oe_widget .oe_checkboxListElement .oe_label:hover:before {
    border-color: black;
}
.oe_widget .oe_radioListElement .oe_label.oe_checked:after,
.oe_widget .oe_checkboxListElement .oe_label.oe_checked:after {
    opacity: 1;
}

.oe_widget .oe_radioListElement .oe_label:before,
.oe_widget .oe_radioListElement .oe_label:after {
    border-radius: 50%;
}

.oe_widget .oe_phoneNumberInput {
    display: flex;
    align-items: baseline;
    flex: 1;
}
.oe_widget .oe_phoneNumberInput .oe_phone_prefix {
    margin-right: 5px;
}
.oe_widget .oe_phoneNumberInput input {
    margin-top: 15px;
    margin-bottom: 30px;
}

.oe_widget .oe_fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

.oe_widget .oe_selector {
    display: block;
    margin-bottom: 30px;
    position: relative;
}
.oe_widget .oe_selector.oe_phoneSelector {
    margin-bottom: 0;
}
.oe_widget .oe_selector .oe_selectorValue {
    background-color: white;
    border: 1px solid gray;
    box-sizing: border-box;
    display: block;
    font-size: 100%;
    padding: 7px;
    transition: 500ms;
}
.oe_widget .oe_selector .oe_selectorValue:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid black;
    content: "";
    height: 0;
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
}
.oe_widget .oe_selector select {
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.oe_widget .oe_selector:hover .oe_selectorValue {
    border-color: black;
}

.oe_widget .oe_fileElement .oe_uploader {
    display: flex;
}
.oe_widget .oe_fileElement .oe_uploader .oe_uploaderButton {
    flex: 1;
}
.oe_widget .oe_fileElement .oe_uploader .oe_uploaderValue {
    border: 1px solid gray;
    padding: 14px;
    text-align: center;
    flex: 1;
}
.oe_widget .oe_fileElement .oe_uploader .oe_uploaderButton:hover {
    background-color: gray;
}

.oe_widget .oe_legacyfileElement .oe_uploader {
    display: block;
}
.oe_widget .oe_legacyfileElement .oe_uploader .oe_uploaderValue {
    font-weight: bold;
}
.oe_widget .oe_legacyfileElement .oe_uploader:hover .oe_uploaderButton {
    background-color: gray;
}

.oe_widget .oe_uploader {
    background-color: white;
    border: 1px dashed gray;
    margin-bottom: 30px;
    padding: 14px;
    position: relative;
}
.oe_widget .oe_uploader .oe_uploaderButton {
    background-color: lightgray;
    display: block;
    padding: 14px;
    text-align: center;
    transition: 500ms;
}
.oe_widget .oe_uploader input[type="file"] {
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.oe_widget .oe_dateSelectListElement .oe_label {
    display: none;
}

.oe_widget .oe_functionsList li,
.oe_widget .oe_functionsSelectedList li {
    list-style-type: none;
}

.oe_widget .oe_functionsList {
    border: 1px solid lightgray;
    font-size: 85%;
    line-height: 1.75;
    padding: 2px 7px;
    margin: -30px 0 30px;
}
.oe_widget .oe_functionsList a {
    color: black;
    text-decoration: none;
}
.oe_widget .oe_functionsList a:hover {
    text-decoration: underline;
}
.oe_widget .oe_functionsList .oe_selected a {
    color: gray;
}

.oe_widget .oe_functions .oe_functions_removeInput {
    padding: 8px;
    position: absolute;
    margin-left: -25px;
}

.oe_widget .oe_functions .oe_functionsSelectedList .oe_functionSelectedDelete {
    color: red;
    text-decoration: none;
}
.oe_widget
    .oe_functions
    .oe_functionsSelectedList
    .oe_functionSelectedDelete:hover {
    text-decoration: underline;
}
.oe_widget
    .oe_functions
    .oe_functionsSelectedList
    .oe_functionSelectedDelete:visited {
    color: inherit;
}

.oe_widget .oe_functions .oe_functionsSelectedList .oe_functionsSelectedItem {
    background-color: lightgray;
    line-height: 1.5;
    margin: 2px;
    padding: 5px 10px;
    display: inline-block;
}

.oe_widget #oe_map {
    height: 250px;
    margin-bottom: 30px;
    width: 100%;
}

.oe_widget #oe_map + .oe_mapResult {
    margin-top: -30px;
}

.oe_widget .oe_mapResult {
    background-color: lightgray;
    box-sizing: border-box;
    margin-bottom: 30px;
    padding: 14px;
}
.oe_widget .oe_mapResult .oe_selector {
    margin-bottom: 0;
}
.oe_widget .oe_mapResult .oe_selector .oe_selectorValue {
    background-color: transparent;
}
.oe_widget .oe_mapResult .oe_department {
    margin-top: 14px;
}
.oe_widget .oe_mapResult .oe_departmentAuto {
    display: block;
    margin-bottom: 14px;
}
.oe_widget .oe_mapResult h4,
.oe_widget .oe_mapResult p {
    margin: 0;
}

.oe_widget .oe_stepIntro,
.oe_widget .oe_blockIntro {
    margin-bottom: 30px;
}

.oe_widget .oe_blockIntro {
    display: block;
}

.oe_widget .oe_blockLabel,
.oe_widget .oe_label {
    display: none;
    margin-bottom: 10px;
}

.oe_widget .oe_blockLabelActive .oe_label {
    display: none;
}

.oe_widget .oe_required .oe_label:after,
.oe_widget .oe_blockRequired .oe_blockLabel:after {
    color: red;
    content: " *";
}
.oe_fileElement .oe_label {
    display: block;
}

.oe_widget .oe_required .oe_radioListElement .oe_label:after,
.oe_widget .oe_required .oe_checkboxListElement .oe_label:after {
    content: "";
}

.oe_widget .oe_optional .oe_label:after,
.oe_widget .oe_blockOptional .oe_blockLabel:after {
    color: gray;
    content: " " attr(data-optional);
    font-size: 85%;
}

.oe_widget .oe_help,
.oe_widget .oe_validation {
    font-size: 85%;
    margin: -25px 0 30px;
}

.oe_widget .oe_help {
    color: gray;
}

.oe_widget .oe_validation {
    color: red;
}

.oe_widget .oe_checkboxElement .oe_help {
    color: inherit;
    font-size: 100%;
    margin: 0 0 10px;
}

.oe_widget .oe_validationList {
    background-color: red;
}
.oe_widget .oe_validationList ul {
    font-size: 85%;
    line-height: 1.75;
    padding: 2px 7px;
}
.oe_widget .oe_validationList ul li {
    list-style-type: none;
}
.oe_widget .oe_validationList a {
    color: white;
    text-decoration: none;
}
.oe_widget .oe_validationList a:hover,
.oe_widget .oe_validationList a:focus {
    text-decoration: underline;
}

.oe_widget.oe_slideUpDown .oe_stepTitle a {
    border-bottom: 1px solid gray;
    display: block;
    padding-bottom: 20px;
    text-decoration: none;
}
.oe_widget.oe_slideUpDown .oe_stepTitle a:hover {
    text-decoration: underline;
}

.oe_widget.oe_slideUpDown .oe_stepContainer {
    display: none;
}

.oe_widget.oe_slideUpDown .oe_activeStep {
    border-bottom: 1px solid gray;
}
.oe_widget.oe_slideUpDown .oe_activeStep .oe_stepTitle a {
    border-bottom: 0;
    padding-bottom: 0;
}
.oe_widget.oe_slideUpDown .oe_activeStep .oe_stepContainer {
    display: block;
}

.oe_widget.oe_slideUpDown .oe_buttons {
    margin-top: 23px;
}

.oe_widget.oe_prevNext .oe_step {
    display: none;
}

.oe_widget.oe_prevNext .oe_activeStep {
    display: block;
}

.oe_widget.oe_prevNext .oe_buttons {
    overflow: hidden;
}
.oe_widget.oe_prevNext .oe_buttons button:first-child {
    float: left;
}
.oe_widget.oe_prevNext .oe_buttons button:last-child {
    float: right;
}

.items-end {
    align-items: flex-end;
    display: flex;
}

.justify-center {
    justify-content: center;
}
