@import "../../styles/variables";

.item {
    flex-shrink: 0;
    position: relative;
    transition-property: transform;
    width: 310px;
    display: flex;
    color: $white;
    margin-right: 56px;
    justify-content: flex-start;
    align-items: flex-start;

    &:last-of-type {
        margin-right: 0;
    }

    a {
        color: currentColor;
        font-size: $text-size-normal;
    }

    .more {
        padding-top: 18px;
        font-weight: bold;

        svg {
            width: 24px;
            height: 24px;
            flex-shrink: 0;
            flex-grow: 0;
            margin-top: -4px;
        }
    }
}

.item:hover .person .name,
.item--show-info .person .name {
    color: $blue-light;
}

.person {
    width: 310px;
    cursor: pointer;
    position: relative;

    @media (max-width: $tablet) {
        min-width: 310px;
    }
}

.item--active {
    cursor: default;
}

.image {
    width: 100%;
    margin-bottom: 24px;
    user-select: none;
    pointer-events: none;
}

.imageHover {
    pointer-events: none;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    transition: opacity 0.1s $default-transition;
    background: $black;
}

.person:hover .imageHover {
    opacity: 1;
}

.name {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 16px;
    transition: color 0.1s $default-transition;
    display: block;
    text-align: center;
}

.position {
    text-align: center;
    display: block;
}

.information {
    flex-flow: column;
    align-items: flex-start;
    max-width: 0;
    transition: opacity 0.2s $default-transition;
    overflow: hidden;
    opacity: 0;
    display: none;
}

.item--show-info {
    width: 730px;

    @media (max-width: $tablet) {
        width: auto;
    }
}

.item--show-info .information {
    max-width: 420px;
    opacity: 1;
    padding-left: 24px;
    display: flex;

    @media (max-width: $tablet) {
        max-width: 100vw;
    }
}

.item--show-info .information h5,
.item--show-info .information ul,
.item--show-info .information p {
    max-width: 400px;

    @media (max-width: $tablet) {
        max-width: 100%;
        min-width: 100%;
    }
}

.information {
    padding-top: 16px;
}

.title {
    font-size: 1.5625em;

    @media (max-width: $tablet) {
        font-size: 1.125em;
    }
}

.description {
    box-sizing: border-box;
    font-size: 1.125em;
    line-height: 1.5;
    margin-top: 16px;

    @media (max-width: $tablet) {
        font-size: 1em;
        line-height: 1.75;
    }
}

.contact {
    margin-top: 18px;
    font-size: 1.125em;
    line-height: 1.5;

    @media (max-width: $tablet) {
        font-size: 1em;
        line-height: 1.44;
    }
}

.contact li {
    margin-bottom: 6px;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.contact li a {
    color: currentColor;

    &:hover {
        text-decoration: underline;
    }
}

.contact li svg {
    margin-right: 16px;
    width: 18px;
    height: 18px;
    position: relative;
    top: -1px;
}

.close {
    margin-top: 64px;
    font-weight: bold;
    font-size: 1.125em;
    cursor: pointer;

    @media (max-width: $tablet) {
        margin-top: 24px;
    }
}

.information .close svg {
    margin-right: 8px;
    position: relative;
    top: -2px;
}
