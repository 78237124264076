@import "../../../styles/variables";
@import "../../../styles/utilities/mixins";

.wrapper {
    display: flex;

    @media (max-width: $desktop) {
        flex-direction: column;
    }
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
}

.video {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    min-height: 338px;
    position: relative;
    width: 100%;

    @media (max-width: $tablet) {
        grid-template-columns: 1fr;
    }

    .frame {
        max-height: 338px;
        max-width: 720px;
        overflow: hidden;
        position: relative;

        iframe {
            width: 100%;

            @media (max-width: $mobile) {
                display: none;
            }
        }
    }
}
