@import "../../styles/variables";

.vacancies {
    display: flex;
    flex-flow: column;
    padding-bottom: 48px;
    width: 566px;

    @media (max-width: 1280px) {
        width: 400px;
    }

    @media (max-width: $desktop) {
        width: 335px;
    }
    @media (max-width: 920px) {
        margin-top: 32px;
        padding-bottom: 0;
        width: 100%;
    }
}
