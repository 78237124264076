@import "../variables";
@import "../utilities/mixins";

.button {
    @include button;

    color: currentColor;
    padding: 5px 30px 10px;
    text-decoration: none;
}

.disabled:disabled {
    cursor: not-allowed;
    opacity: 0.75;
}
