@import "../../styles/variables";

.content {
    color: currentColor;
    margin-bottom: 96px;
    margin-top: -10px;
    width: calc(100% - 294px - 64px);

    @media (max-width: $desktop) {
        margin-bottom: 75px;
        width: 100%;
    }
}

.content :global(.button) {
    margin-top: 24px;
}
