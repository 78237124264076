@import "../variables";

input[type=range] {
    appearance: none;
    border: 0;
    outline: none;
    width: 100%;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    background: $yellow-light;
    cursor: pointer;
    height: 4px;
    width: 100%;
}

input[type=range]::-webkit-slider-thumb {
    appearance: none;
    background: $yellow;
    border: 1px solid $yellow;
    border-radius: 8px;
    cursor: pointer;
    height: 16px;
    margin-top: -6px;
    width: 16px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: $yellow-light;
}

input[type=range]::-moz-range-track {
    background: $yellow-light;
    border: 0.2px solid rgba(1, 1, 1, 0);
    border-radius: 1.3px;
    cursor: pointer;
    height: 8.4px;
    width: 100%;
}

input[type=range]::-moz-range-thumb {
    background: $black;
    border: 1px solid $black;
    border-radius: 2px;
    cursor: pointer;
    height: 16px;
    width: 16px;
}

input[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 4.8px 0;
    color: transparent;
    cursor: pointer;
    height: 8.4px;
    width: 100%;
}

input[type=range]::-ms-fill-lower {
    background: $yellow-light;
    border: 0.2px solid rgba(1, 1, 1, 0);
    border-radius: 2.6px;
}

input[type=range]::-ms-fill-upper {
    background: $yellow-light;
    border: 0.2px solid rgba(1, 1, 1, 0);
    border-radius: 2.6px;
}

input[type=range]::-ms-thumb {
    background: $black;
    border: 1px solid $black;
    border-radius: 2px;
    cursor: pointer;
    height: 16px;
    margin-top: 0;
    width: 16px;
}

input[type=range]:focus::-ms-fill-lower {
    background: $yellow-light;
}

input[type=range]:focus::-ms-fill-upper {
    background: $yellow-light;
}
