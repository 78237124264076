@import "../../../styles/variables";
@import "../../../styles/utilities/mixins";

.title {
    @include title;
    @include title-spacing;

    composes: section-title from "../../../styles/elements/section.scss";
    font-family: $main-font;
    text-align: center;
    text-transform: uppercase;
}

.description {
    text-align: center;

    @media (max-width: $mobile) {
        margin-top: 24px;
    }
}

.wrapper {
    padding-top: 48px;
    //position: relative;
    text-align: center;
    width: 100%;

    @media (max-width: $tablet) {
        padding-top: 0;
    }
}

.timeline {
    display: flex;
    justify-content: space-evenly;
    margin: 48px 0;
    padding: 0;
    text-align: center;
    word-break: keep-all;

    @media (max-width: $tablet) {
        flex-direction: column;
        margin: 24px 0;
    }
}

.item {
    position: relative;
    width: 100%;

    .tooltip {
        background: $white;
        bottom: 100%;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
        left: 0;
        margin-bottom: 40px;
        opacity: 0;
        padding: 8px;
        position: absolute;
        right: 0;
        transition: opacity 0.2s linear, visibility 0.2s linear;
        visibility: hidden;
        z-index: +1;

        &::after {
            border-color: $white transparent transparent transparent;
            border-style: solid;
            border-width: 10px;
            content: " ";
            left: 50%;
            margin-left: -10px;
            position: absolute;
            top: 100%;

            @media (max-width: $tablet) {
                display: none;
            }
        }

        @media (max-width: $tablet) {
            box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
            font-size: $text-size-small;
            //left: 24px;
            left: 24px;
            max-width: 300px;
            text-align: left;
            transform: unset;
            width: 300px;
        }
    }

    .label {
        border-top: 8px solid $purple;
        display: flex;
        justify-content: center;
        padding: 8px;
        position: relative;

        @media (max-width: $tablet) {
            border-left: 4px solid $purple;
            border-top: 0;
            justify-content: flex-start;
        }

        span {
            cursor: pointer;
            padding-left: 10px;
            padding-top: 20px;

            @media (max-width: $tablet) {
                padding-bottom: 10px;
                padding-top: 0;
                text-align: left;
            }

            &::before {
                background: $yellow;
                border-radius: 100%;
                content: attr(data-step);
                font-family: "Stolzl Display", sans-serif;
                font-size: $text-size-medium;
                height: 48px;
                left: 50%;
                line-height: 48px;
                position: absolute;
                text-align: center;
                top: -28px;
                transform: translateX(-50%);
                width: 48px;

                @media (max-width: $tablet) {
                    bottom: -100%;
                    font-size: $text-size-small;
                    height: 24px;
                    left: -2px;
                    line-height: 24px;
                    top: 0;
                    width: 24px;
                }
            }
        }
    }

    &:hover {
        .label {
            span::before {
                background: $blue;
                color: $white;
            }
        }

        .tooltip {
            opacity: 1;
            visibility: visible;
        }
    }
}
