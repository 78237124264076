@import "../../styles/variables";

.button {
    bottom: 12px;
    cursor: pointer;
    height: 32px;
    position: fixed;
    right: 8px;
    width: 32px;
    z-index: 999;

    &:hover {
        transform: translateY(-10px);
        transition: transform 0.5s $default-transition;
    }

    svg {
        color: $white;
        filter: drop-shadow(3px 3px 0 rgba(0, 0, 0, 0.25));
        height: 32px;
        width: 32px;
    }
}
