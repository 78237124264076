@import "variables";
@import "tailwind/grid";
@import "tailwind/spacing";

$purple-50: #ffd3ff;
$purple-100: #e9c5ff;
$purple-200: #cba7f3;
$purple-300: #ad89d5;
$purple-400: #8f6bb7;
$purple-500: #714d99;
$purple-600: #532f7b;
$purple-700: #35115d;
$purple-800: #17003f;
$purple-900: #000021;

.bg-white {
    background: $white;
}

.bg-black {
    background: $black;
}

.bg-blue {
    background: $blue;
}

.bg-blue-light {
    background: $blue-light;
}

.bg-red {
    background: $red;
}

.bg-purple {
    background: $purple;
}

.bg-yellow {
    background: $yellow;
}

.text-white {
    color: $white;
}

.text-black {
    color: $black;
}

.text-yellow {
    color: $yellow;
}

.text-yellow-light {
    color: $yellow-light;
}

.text-red {
    color: $red;
}

.text-blue {
    color: $blue;
}

.flex {
    display: flex;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.hover-bg-blue:hover {
    background: $blue;
}

.hover-bg-blue-light:hover {
    background: $blue-light;
}

.hover-bg-blue-lighter:hover {
    background: $blue-lighter;
}

.py-4 {
    padding: 1rem 0;
}

.px-4 {
    padding: 0 1rem;
}

.align-left {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-between {
    justify-content: space-between;
}

.justify-evenly {
    justify-content: space-evenly;
}

.w-full {
    width: 100%;
}

.w-screen {
    width: 100vw;
}

.h-full {
    width: 100%;
}

.h-screen {
    height: 100vh;
}

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.fixed {
    position: fixed;
}

.text-purple {
    color: $purple-500;
}

.gap-1 {
    grid-gap: 1rem;
}

.gap-4 {
    grid-gap: 4rem;
}

.gap-8 {
    grid-gap: 8rem;
}

.p-4 {
    padding: 1rem;
}

.p-8 {
    padding: 2rem;

    @media (max-width: $tablet) {
        padding: 1rem;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.w-max {
    width: max-content;
}
