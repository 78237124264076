@import "../../styles/variables";

.dropdown {
    color: $white;
    margin-right: 12px;
    transition: all 1s $default-transition;
    width: 200px;

    @media (max-width: $mobile) {
        margin-top: 12px;
        width: 100%;
    }
}

.list {
    background-color: $white;
    display: none;
    flex-direction: column;
    min-width: 200px;
    position: absolute;
    transition: all 1s $default-transition;
    user-select: none;
    z-index: 9;

    @media (max-width: $mobile) {
        width: calc(100% - 32px);
    }

    a {
        color: $black;
        padding: 12px;
        width: 100%;

        svg {
            height: 24px;
            margin-right: 12px;
            width: 24px;
        }

        &:hover {
            background-color: $light;
        }
    }
}

.button {
    align-items: center;
    background-color: $blue-light;
    cursor: pointer;
    display: flex;
    font-weight: 900;
    height: 50px;
    justify-content: space-between;
    min-width: 200px;
    padding: 7px 16px 10px;
    transition: all 1s $default-transition;
    user-select: none;

    span {
        border: 0;
        color: $white;
        display: inline-flex;
        font-size: $text-size-medium;
        font-weight: 900;
        text-decoration: none;
        white-space: inherit;
        z-index: 2;

        @media (max-width: $tablet) {
            font-size: $text-size-normal;
        }
    }

    svg {
        height: 24px;
        transition: transform 0.2s $default-transition;
        width: 24px;
    }

    &:hover {
        background-color: $blue-lighter;

        svg {
            transform: translateX(4px);
        }
    }
}

.active {
    display: flex;
}
