@import "../../styles/variables";

.item {
    background: $black;
    color: $white;
    display: flex;
    margin-bottom: 16px;
    padding: 24px;
    transition: transform 0.2s $default-transition;

    @media (min-width: 920px) {
        align-items: flex-end;
    }
}

.image {
    height: 184px;
    margin-left: 12px;
    margin-right: 42px;
    min-width: 121px;
    object-fit: contain;

    @media (max-width: 1280px) {
        margin-left: 0;
    }

    @media (max-width: $mobile) {
        align-items: flex-end;
        display: flex;
        margin-right: 24px;
        min-width: 92px;
        width: 92px;
    }

    @media (max-width: 360px) {
        display: none;
    }

    svg {
        @media (max-width: $mobile) {
            height: 140px;
            width: 100%;
        }
    }
}

.content {
    display: flex;
    flex-flow: column;
    width: calc(100% - 24px);
}

.title {
    font-family: $title-font;
    font-size: 20px;
    line-height: 0.9;
    margin-bottom: 8px;
    text-transform: uppercase;

    @media (max-width: $widescreen) {
        font-size: $title-size-tablet;
        line-height: 1.1;
    }
}

.position {
    font-size: 1.5625em;

    @media (max-width: $tablet) {
        font-size: 1.125em;
    }
}

.more {
    color: $blue-light;
    display: block;
    font-size: 1.25em;
    font-weight: bold;
    margin-top: 24px;
    white-space: nowrap;

    @media (max-width: 920px) {
        font-size: 1.125em;
        margin-top: auto;
    }
}

.more svg {
    margin-left: 4px;
    position: relative;
    top: -1px;
    transition: transform 0.2s $default-transition;
}

.item:hover {
    transform: translateY(-2px);
}

.item:hover .more svg {
    transform: translateX(4px);
}
