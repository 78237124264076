@import "variables";
@import "utilities/mixins";

.rnFormContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.rnTitlePrefix {
    display: flex;
}

.rnVacancyTitle {
    hyphens: auto;
}

@media (max-width: 480px) {
    .rnVacancyTitle {
        font-size: 1.5rem;
        line-height: 1;
    }
}

.rnFieldList {
    display: flex;
    flex-direction: column;
    gap: 24px;

    fieldset {
        display: grid;
        grid-template-columns: 1fr 1fr;
        flex-direction: column;
        gap: 24px;

        @media (max-width: $tablet) {
            grid-template-columns: 1fr;
        }

        &:nth-of-type(2) {
            display: flex;
            flex-direction: column;
            gap: 12px;

            legend {
                display: block;
                font-size: $text-size-large;
                margin-bottom: 1rem;
            }

            label {
                display: inline-block;
            }
        }

        legend {
            display: none;
        }
    }
}

.rnFormGroup {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 0.25rem;
}

.rnFileUpload {
    display: flex;
    flex-direction: column-reverse;
    gap: 0.25rem;
}

.rnmotivation-text {
    textarea {
        width: 100%;
    }
}

.rnagree-privacy,
.rnwhatsapp-consent {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    gap: 12px;
    justify-content: flex-end;
}

.rnCheckbox.checked {
    opacity: 1;
}

.rnButton {
    @include button;
    @include button-blue;
}

.rnErrors {
    color: $red;
}

.rncvFile {
    grid-column-start: 1;

    label {
        display: block;
        margin-bottom: 0.5rem;
    }

    input[type="file"] {
        background: none;
        border: none;
        color: transparent;
        outline: none;
        padding: 0;
        width: 200px;

        &::-webkit-file-upload-button {
            visibility: hidden;
        }

        &:before {
            content: "Uploaden";
            color: #00b0b8;
            display: inline-block;
            background: #fff;
            padding: 10px 30px;
            outline: none;
            white-space: nowrap;
            cursor: pointer;
            font-weight: 700;
            font-size: 1.25rem;
            width: 200px;
            text-align: center;
        }
    }
}

.rnLabel {
    .required {
        &:after {
            color: $red;
            content: "*";
            font-size: 1rem;
        }
    }
}

.rnmotivation-text {
    grid-column-start: 1;
}

#agree-privacy,
#whatsapp-consent {
    height: 24px !important;
    min-height: auto;
}
