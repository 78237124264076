@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.tabs {
    background: $yellow;
}

.tabs--yellow {
    @include bg-yellow;
}

.tabs--yellow-light {
    @include bg-yellow-light;
}

.tabs--purple {
    @include bg-purple;
}

.tabs--blue {
    @include bg-blue;
}

.tabs--blue-light {
    @include bg-blue-light;
}

.tabs--red {
    @include bg-red;
}

.tabs--green {
    @include bg-green;
}

.tabs--light {
    @include bg-light;
}

.tabs--white {
    @include bg-white;
}

.tabs--black {
    @include bg-black;
}

.wrapper {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    @media (max-width: $desktop) {
        flex-flow: column-reverse;
    }
}

.figure {
    align-self: flex-end;
    margin-left: -126px;
    margin-right: 96px;
    max-width: calc(574px + 126px);
    width: calc(574px + 126px);

    @media (max-width: $widescreen) {
        margin-left: 0;
        width: 420px;
    }

    @media (max-width: $desktop) {
        margin-right: 32px;
    }

    @media (max-width: 920px) {
        align-self: flex-start;
        margin-right: 0;
    }

    @media (max-width: $mobile) {
        left: -16px;
        position: relative;
        width: calc(100% + 32px);
    }

    img {
        width: 100%;
    }
}

:global(.ie) .figure img {
    height: 100%;
    width: auto;
}

.figure--loading {
    min-height: 400px;
}

.content {
    margin-bottom: 96px;
    padding-top: 96px;
    width: calc(100% - 574px - 96px);

    @media (max-width: $widescreen) {
        width: calc(100% - 420px - 96px);
    }

    @media (max-width: $desktop) {
        margin-bottom: 48px;
        width: calc(100% - 420px - 32px);
    }

    @media (max-width: 920px) {
        margin-bottom: 32px;
        max-width: 660px;
        padding-top: 54px;
        width: 100%;
    }
}

.list {
    display: flex;
    font-size: $text-size-normal;
    font-weight: bold;
    margin-bottom: 64px;
    user-select: none;

    @media (max-width: $widescreen) {
        flex: 1;
        flex-wrap: wrap;
        padding-right: 100px;
    }

    @media (max-width: 1180px) {
        padding-right: 0;
    }

    @media (max-width: $desktop) {
        margin-bottom: 32px;
    }

    @media (max-width: 920px) {
        padding-right: 100px;
    }

    @media (max-width: $mobile) {
        padding-right: 0;
    }

    li {
        cursor: pointer;
        margin-right: 32px;
        position: relative;
        white-space: nowrap;

        @media (max-width: $widescreen) {
            margin-bottom: 16px;
        }

        &:last-of-type {
            @media (max-width: $widescreen) {
                margin-right: 0;
            }
        }

        &::after {
            background: currentColor;
            bottom: -8px;
            content: "";
            height: 2px;
            left: 0;
            position: absolute;
            transition: width 0.2s $default-transition;
            width: 0;
        }
    }

    li:global(.is-active) {
        pointer-events: none;

        &::after {
            width: 100%;
        }
    }
}

.description :global(.loading) {
    display: inline-block;
    margin-left: 25px;
    width: auto;
}

.title,
.description h2 {
    font-size: 2.5em;
    line-height: 1.2;
    margin-bottom: 32px;

    @media (max-width: $desktop) {
        font-size: $text-size-large;
        line-height: 1.16;
        margin-bottom: 24px;
    }
}

.description p {
    @media (max-width: $desktop) {
        font-size: $text-size-small;
        line-height: 1.75;
    }
}

.description p a {
    color: $black;

    &:hover {
        text-decoration: none;
    }
}

.article p {
    margin-bottom: 16px;

    &:last-of-type {
        margin-bottom: 0;
    }
}
