figure {
    margin: 0;
}

img {
    max-width: 100%;
}

iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
