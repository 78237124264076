@import "../../../styles/variables";
@import "../../../styles/utilities/mixins";

.wrapper {
    display: flex;
    flex-direction: column;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1.5rem;
}

.tag {
    color: $white;
    font-size: 2rem;
    font-weight: bold;
    padding: 0.5rem;

    svg {
        color: $yellow;
        fill: $yellow;
        margin-right: 1rem;
        transform: rotate(90deg) scale(2);
    }

    @media (max-width: $desktop) {
        font-size: 1rem;
        padding: 0;

        svg {
            transform: rotate(90deg) scale(1);
        }
    }
}
