@import "../../styles/variables";

.text {
    background: $purple;
    color: $white;
    overflow: hidden;
    position: relative;
}

.wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: $desktop) {
        align-items: flex-start;
        flex-flow: column;
    }
}

.arrows {
    left: 0;
    position: absolute;
    top: 0;

    @media (max-width: $widescreen) {
        height: 540px;
        width: 550px;
    }

    @media (max-width: 1140px) {
        height: 340px;
        width: 340px;
    }

    @media (max-width: 920px) {
        height: 570px;
        top: -90px;
        width: 590px;
    }

    @media (max-width: $mobile) {
        top: -170px;
    }
}

.figure {
    align-items: flex-start;
    display: flex;
    height: auto;
    justify-content: center;
    margin-right: 96px;
    margin-top: 88px;
    max-width: 493px;
    position: relative;
    width: 493px;

    @media (max-width: $desktop) {
        margin-right: 32px;
    }

    @media (max-width: $mobile) {
        width: 100%;
    }

    svg {
        height: auto;
        width: 100%;
    }
}

.content {
    composes: content from "../../components/content-image/styles.module.scss";
}

.logo {
    background: $black;
    height: 200px;
    margin-bottom: 32px;
    width: 100%;
}

.title {
    color: $yellow-light;
    composes: title from "../../components/content-image/styles.module.scss";
}

.description {
    composes: description from "../../components/content-image/styles.module.scss";
}

.description :global(.loading) {
    display: inline-block;
    margin-left: 25px;
    width: auto;
}

.content :global(.button) {
    margin-top: 24px;
}
