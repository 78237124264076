@import "../variables";
@import "../utilities/mixins";

.section {
    @include grid-column;

    padding: 56px;

    @media (max-width: $desktop) {
        padding: 32px 0;
    }

    @media (max-width: $mobile) {
        padding: 24px 0;
    }

    &.overlap {
        padding-bottom: 400px !important;

        @media (max-width: $tablet) {
            padding-bottom: 48px !important;
        }
    }

    &.stick-bottom {
        padding-bottom: 0 !important;
    }

    &.no-padding {
        @media (max-width: $mobile) {
            padding: 0 !important;
        }
    }

    &.section--row {
        flex-direction: row;

        &:global(.wrapper) {
            max-width: 600px;
        }
    }

    &.section--yellow {
        @include bg-yellow;
    }

    &.section--yellow-light {
        @include bg-yellow-light;
    }

    &.section--purple {
        @include bg-purple;
    }

    &.section--blue {
        @include bg-blue;
    }

    &.section--blue-light {
        @include bg-blue-light;
    }

    &.section--red {
        @include bg-red;
    }

    &.section--green {
        @include bg-green;
    }

    &.section--light {
        @include bg-light;
    }

    &.section--white {
        @include bg-white;

        input {
            background: $light;
            border-color: $light;
        }
    }

    &.section--black {
        @include bg-black;
    }
}

.section-title {
    @include title;

    margin-bottom: 12px;
}
