@import "../../styles/variables";

.navigation {
    display: flex;
    flex-flow: column;
    line-height: 2.38;

    @media (max-width: $tablet) {
        order: 1;
    }

    a {
        transition: font-weight 0.2s $default-transition;

        svg {
            height: 12px;
            margin-left: 8px;
            opacity: 0;
            position: relative;
            top: -1px;
            transition: transform 0.2s $default-transition,
                opacity 0.2s $default-transition;
            width: 24px;
        }

        &:hover svg {
            opacity: 1;
            transform: translateX(4px);
        }
    }
}

.item--is-active {
    font-weight: bold;

    svg {
        opacity: 0;
        transform: translateX(24px);
    }

    &:hover svg {
        opacity: 0 !important;
    }
}

.navigation a:hover {
    font-weight: bold;
}
