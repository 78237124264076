@import "../../../styles/variables";
@import "../../../styles/utilities/mixins";

.slider {
    @include grid-column;
}

.row {
    composes: row from "../sales-cases/styles.module.scss";
}

.title {
    composes: title from "../sales-cases/styles.module.scss";
    word-break: break-word;
}

.description {
    composes: description from "../sales-cases/styles.module.scss";
    word-break: break-word;
}

.item {
    @include grid-column;

    overflow: hidden;
}

.figure {
    padding-top: 75%;
    position: relative;
    width: 100%;
}

.image {
    aspect-ratio: 4 / 3;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
}

.caption {
    background: $black;
    bottom: 0;
    color: $yellow-light;
    left: 0;
    line-height: initial;
    padding: 16px;
    position: absolute;
    right: 0;
    word-break: keep-all;
    z-index: 999;

    @media (max-width: $tablet) {
        padding: 8px;
    }

    span {
        @include font;

        font-weight: bold;
    }
}
