@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.overview {
    background-color: $light;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: $mobile) {
        align-content: center;
        flex-direction: column;
    }
}

.item {
    background: $white;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    margin-right: 24px;
    max-width: calc(33% - 16px);
    transition: transform 0.2s $default-transition;
    width: calc(33% - 16px);

    @media (max-width: $tablet) {
        margin-right: 24px;
        max-width: calc(50% - 12px);
        width: calc(50% - 12px);
    }

    @media (max-width: $mobile) {
        margin: 0 0 12px;
        max-width: 100vw;
        width: 100%;
    }

    &:nth-child(3n) {
        margin-right: 0;
    }

    &:hover {
        transform: translateY(-4px);
    }

    .background {
        background-color: $black;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .image {
        background-color: $black;
        height: 100%;
        max-height: 266px;
        min-height: 189px;
        overflow: hidden;
        position: relative;

        @media (max-width: $desktop) {
            min-height: 160px;
        }
    }

    .heading {
        background-color: $black;
        color: $yellow-light;
        margin-top: auto;
        padding: 18px 24px;

        @media (max-width: $desktop) {
            padding: 14px 18px;
        }

        span {
            display: block;
            font-size: 1rem;
            font-weight: bold;
            line-height: 0.88;
            max-height: 103px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: uppercase;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        height: 200px;
        max-height: 200px;
        padding: 18px 24px;

        @media (max-width: $desktop) {
            max-height: 128px;
            padding: 14px 18px;
        }

        @media (max-width: $tablet) {
            max-height: 999px;
        }

        @media (max-width: $mobile) {
            height: auto;
        }

        .description {
            color: $dark;
            display: block;
            font-size: $text-size-normal;
            height: 125px;
            line-height: 1.42;
            margin-bottom: 12px;
            max-height: 125px;
            overflow: hidden;
            text-overflow: ellipsis;

            @media (max-width: $desktop) {
                font-size: $text-size-small;
                height: 70px;
                line-height: 1.44;
            }

            @media (max-width: $tablet) {
                height: auto;
            }
        }

        .more {
            color: $blue-light;
            display: inline-block;
            font-size: 1.25rem;
            font-weight: bold;
            margin-top: auto;

            @media (max-width: 1024px) {
                font-size: $text-size-medium;
            }

            @media (max-width: 768px) {
                font-size: $text-size-small;
            }

            svg {
                margin-left: 12px;
                position: relative;
                top: -1px;
                transition: transform 0.2s $default-transition;

                @media (max-width: 768px) {
                    display: none;
                }
            }
        }
    }
}

.sub {
    composes: sub from "../vacancy/styles.module.scss";
}

.city {
    composes: city from "../vacancy/styles.module.scss";
}

.item--missed {
    composes: closed from "../vacancy/styles.module.scss";
}

.afwezig {
    composes: closed from "../vacancy/styles.module.scss";
}
