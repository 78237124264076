@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.stories {
    background: $light;
    color: $black;
    padding: 56px 0;

    @media (max-width: $desktop) {
        padding: 48px 0;
    }

    @media (max-width: $tablet) {
        padding: 32px 0;
    }
}

.stories--yellow {
    @include bg-yellow;
}

.stories--light {
    @include bg-light;
}

.stories--grayscale {
    filter: grayscale(1);
}
