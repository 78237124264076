@import "../../styles/variables";

.cross {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-flow: column;
    height: 14px;
    justify-content: center;
    margin-right: 8px;
    position: relative;
    top: 1px;
    transition: margin-right 0.25s $default-transition;
    width: 22px;

    span {
        background-color: currentColor;
        height: 3px;
        transition: transform 0.25s $default-transition;
        width: 22px;

        &:first-child {
            margin-bottom: 6px;
            transform-origin: left;
        }
    }
}

.cross--active span {
    transform: rotate(45deg) translate(-4px, -2px);

    &:first-child {
        transform: rotate(-45deg) translate(-8px, 10px);
    }
}

.expand {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-flow: column;
    height: 14px;
    justify-content: center;
    margin-right: 8px;
    position: relative;
    top: 1px;
    transition: margin-right 0.25s $default-transition, transform 0.25s linear;
    width: 22px;

    span {
        background-color: currentColor;
        height: 3px;
        transition: transform 0.25s $default-transition;
        width: 22px;

        &:first-child {
            transform: rotate(-90deg) translate(-14px, 10px);
            transform-origin: left;
        }
    }
}

.expand--active {
    transform: rotate(45deg);
}
