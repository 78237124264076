@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.arrow {
    border: solid $black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.right {
    transform: rotate(-45deg);
}

.bar {
    @media (max-height: $tablet) {
        @include grid-column;
    }
}

.bar :global(.simplebar-content) {
    @include grid-column;
    height: 100%;
}

.container {
    background-color: $white;
    color: $black;
    font-size: $text-size-medium;
    height: auto;
    min-height: 250px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    right: -298px;
    top: 80px;
    transition: right 0.2s $default-transition;
    width: 320px;
    z-index: 9;

    @media (max-width: $desktop) {
        top: 60px;
    }

    @media (max-width: $tablet) {
        height: 100%;
        margin-top: 60px;
        right: 0;
        top: 100vh;
        transition: top 0.2s $default-transition;
        width: 100vw;
        z-index: 9;
    }

    @media (max-width: $mobile) {
        margin-top: 40px;
    }

    .contact {
        background-color: $light;
        padding: 24px;
        width: 100%;

        a {
            color: $black;
        }

        ul {
            margin-bottom: 48px;
            li {
                margin-bottom: 18px;

                svg {
                    height: 18px;
                    margin-right: 16px;
                    width: 18px;
                }

                a {
                    display: inline-block;
                    text-decoration: underline;
                    white-space: nowrap;

                    &:hover {
                        text-decoration: none;
                    }
                }

                &.route a {
                    text-decoration: none;
                    margin-bottom: 48px !important;
                }

                &.address {
                    line-height: 1.9;
                    margin-bottom: 20px;
                }
            }
        }

        @media (max-width: $desktop) {
            height: 100vh;

            ul {
                li {
                    a {
                        svg {
                            margin-top: 3px;
                            position: relative;
                            top: -1px;
                        }
                    }

                    &:last-of-type a {
                        padding: 0;
                    }
                }
            }
        }
    }
}

.social-title {
    font-size: $text-size-normal;
    margin-bottom: 12px;
}

.social {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 32px;

    @media (max-width: $desktop) {
        justify-content: space-between;
        margin-bottom: 50px;
        max-width: 60px;
    }
}

.social a {
    margin-right: 18px;
}

.social a svg {
    height: 23px;
    transition: opacity 0.2s $default-transition,
        transform 0.2s $default-transition;
    width: 23px;

    @media (max-width: $tablet) {
        height: 32px;
        width: 32px;
    }
}

.social a:hover svg {
    opacity: 0.65;
    transform: translateY(-2px);
}

.navigation--enter-done {
    right: 0;

    @media (max-width: $tablet) {
        left: 0;
        right: auto;
        top: 0;
    }
}
