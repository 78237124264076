@import "../../../styles/variables";
@import "../../../styles/utilities/mixins";

.cases {
    @include grid-column;
}

.row {
    padding: 24px 0;

    @media (max-width: $mobile) {
        padding: 12px 0;
    }
}

.title {
    @include title;
    @include title-spacing;

    composes: section-title from "../../../styles/elements/section.scss";
    font-family: $main-font;
    text-align: center;
    text-transform: uppercase;
}

.description {
    text-align: left;

    @media (max-width: $mobile) {
        margin-top: 24px;
    }
}

.items {
    margin-top: 56px;
    width: 100%;

    @media (max-width: $mobile) {
        margin-top: 24px;
    }
}

.item {
    column-gap: 24px;
    display: grid;
    font-size: $text-size-medium;
    grid-column-gap: 24px;
    grid-template-columns: 1fr;

    @media (max-width: $tablet) {
        grid-row-gap: 12px;
        grid-template-columns: 1fr;
    }

    div {
        @include grid-column;

        background: $white;
        block-size: 100%;
        color: $black;
        inline-size: 100%;
        padding: 24px;

        @media (max-width: $tablet) {
            padding: 12px;
        }

        h3 {
            text-align: center;
            text-transform: uppercase;

            @media (max-width: $mobile) {
                font-size: $text-size-normal;
            }
        }

        p {
            margin-top: 24px;
            text-align: left;

            @media (max-width: $mobile) {
                margin-top: 8px;
            }
        }

        .logo {
            display: flex;
            height: auto;
            place-content: center;
            width: 100%;

            @media (max-width: $mobile) {
                display: none;
            }

            img {
                max-height: 100px;
                object-fit: contain;
            }
        }
    }
}

.prev {
    display: inline-block;
}
