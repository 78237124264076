@import "../variables";

html {
    font-size: 100%;
}

body {
    background: $white;
    color: $black;
    font-family: $main-font;
    font-size: 16px;
    line-height: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $main-font;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 0;
}

h1 {
    font-size: 4rem;
    line-height: 1;
}

h2 {
    font-size: 2.5rem;
    line-height: 1;
}

h3 {
    font-size: $text-size-large;
}

h4 {
    font-size: $text-size-medium;
}

p {
    font-size: $text-size-normal;
    line-height: 1.5;
    margin-bottom: 0;
    margin-top: 0;
}

a {
    text-decoration: none;
}

p a,
address a {
    text-decoration: underline;

    &:hover {
        opacity: 0.8;
    }
}

ul {
    //font-size: $text-size-small;
    line-height: normal;
    list-style: disc outside;
    margin: 0;
    margin-left: 1rem;
    padding: 0;
}

.blue {
    color: #00f;
}

.purple {
    color: #800080;
}

.green {
    color: #008000;
}

.is-2 {
    font-size: $title-size-desktop;

    @media (max-width: $tablet) {
        font-size: $title-size-tablet;
    }

    @media (max-width: $mobile) {
        font-size: $title-size-mobile;
    }
}

p.content {
    font-size: $text-size-normal;
    margin: 12px 0;
}
