@import "../../styles/variables";

.copyright {
    background: $black;
    color: $white;
    padding-bottom: 22px;

    @media (max-width: $tablet) {
        font-size: 1.125em;
        padding-bottom: 85px;
    }

    @media (max-width: $mobile) {
        padding-bottom: 32px;
    }
}

.copyright span {
    opacity: 0.5;

    @media (max-width: $tablet) {
        display: inline-block;
        line-height: normal;
        max-width: 214px;
        order: 2;
    }
}

.copyright a {
    color: $white;
    margin-right: 120px;
    text-decoration: underline;

    &:last-of-type {
        margin-right: 0;
    }

    &:hover {
        text-decoration: none;
    }
}

.wrapper {
    display: flex;

    @media (max-width: $tablet) {
        flex-flow: column;
    }
}

.navigation {
    display: flex;

    @media (max-width: $tablet) {
        display: flex;
        flex-flow: column;
        margin-bottom: 38px;
        order: 1;
    }
}

.navigation a {
    margin-left: 32px;
    margin-right: 0;

    @media (max-width: $tablet) {
        margin-bottom: 10px;
        margin-left: 0;
    }
}
