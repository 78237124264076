@import "../../styles/variables";

.content {
    color: currentColor;
    padding: 56px 0;

    @media (max-width: $desktop) {
        padding: 32px 0;
    }
}

.title {
    font-size: 4.375em;
    hyphens: auto;
    line-height: 1.07;
    margin-bottom: 32px;
    margin-top: 0;

    @media (max-width: $desktop) {
        font-size: 2.1875em;
        line-height: 1.2;
        margin-bottom: 16px;
    }
}

.description {
    p {
        font-size: $text-size-normal;
        line-height: 1.72;
        margin-bottom: 32px;

        @media (max-width: $desktop) {
            font-size: $text-size-small;
            line-height: 1.75;
        }
    }

    h2 {
        font-size: 2.5em;
        line-height: 1.2;
        margin-bottom: 32px;

        @media (max-width: $desktop) {
            font-size: $text-size-large;
            line-height: 1.16;
            margin-bottom: 16px;
        }
    }

    h3 {
        font-size: $text-size-medium;
        line-height: 1.2;
        margin-bottom: 8px;

        @media (max-width: $desktop) {
            font-size: $text-size-normal;
            line-height: 1.16;
            margin-bottom: 4px;
        }
    }

    iframe {
        border: 0;
    }
}

.description :global(.btn) {
    margin-bottom: 24px;
}

.description *:last-child {
    margin-bottom: 0;
}
