.grid {
    display: grid;
}

$grid-columns: 12;
$grid-rows: 6;

@for $i from 1 through $grid-columns {
    .grid-cols-#{$i} {
        grid-template-columns: repeat($i, minmax(0, 1fr));
    }

    .sm-grid-cols-#{$i} {
        @media (min-width: 620px) {
            grid-template-columns: repeat($i, minmax(0, 1fr));
        }
    }

    .md-grid-cols-#{$i} {
        @media (min-width: 768px) {
            grid-template-columns: repeat($i, minmax(0, 1fr));
        }
    }

    .lg-grid-cols-#{$i} {
        @media (min-width: 1024px) {
            grid-template-columns: repeat($i, minmax(0, 1fr));
        }
    }
}

@for $i from 1 through $grid-columns {
    .col-span-#{$i} {
        grid-column: span $i / span $i;
    }
}

.col-auto {
    grid-column: auto;
}

.col-span-full {
    grid-column: 1 / -1;
}

@for $i from 1 through $grid-rows {
    .grid-rows-#{$i} {
        grid-template-rows: repeat($i, minmax(0, 1fr));
    }
}

.grid-cols-none {
    grid-template-columns: none;
}

.grid-rows-none {
    grid-template-rows: none;
}

.gap-0 {
    gap: 0;
}

.gap-2 {
    gap: 0.5rem;
}

.gap-4 {
    gap: 1rem;
}

.gap-6 {
    gap: 1.5rem;
}

.gap-8 {
    gap: 2rem;
}

@media (min-width: 768px) {
    .md-gap-4 {
        gap: 1rem;
    }

    .md-gap-8 {
        gap: 2rem;
    }
}

@media (min-width: 1024px) {
    .lg-gap-8 {
        gap: 2rem;
    }
}
