@import "../../styles/variables";

.wrapper {
    display: flex;
    padding-bottom: 0;

    h1 {
        margin-bottom: 20px;
    }

    p {
        font-size: $text-size-normal;
    }

    ul {
        font-size: $text-size-normal;
        line-height: 1.5;
        list-style: initial;
        margin-left: 20px;
        padding: 10px 0;
    }

    :global(.column-6) {
        flex-grow: 1;

        @media (max-width: $tablet) {
            &:last-of-type {
                display: none;
            }
        }
    }

    .notice {
        font-size: $text-size-small;
        padding: 10px 0;
    }
}

.button {
    background-color: $blue-light;
    color: $white;
    composes: button from "../../components/button/styles.module.scss";
    cursor: pointer;
    font-size: $text-size-normal;
    font-weight: bold;
    max-width: 300px;
}
