@import "../../styles/variables";

.wrapper {
    align-items: flex-start;
    display: flex;
    margin-top: 56px;

    @media (max-width: $desktop) {
        flex-direction: column-reverse;
    }
}

:global(.ie) .wrapper {
    align-items: flex-start;
}

.sidebar {
    bottom: 0;
    color: $black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 24px;
    max-width: 320px;
    top: 128px;
    width: 100%;

    @media (max-width: $widescreen) {
        top: 96px;
    }

    @media (max-width: $desktop) {
        margin-right: 0;
        width: 100%;
    }

    @media (max-width: $mobile) {
        width: 100%;
    }
}

.quote {
    color: $yellow-light;
    font-size: 1.75em;
    font-weight: bold;
    hyphens: auto;
    line-height: 1.28;
    margin-bottom: 24px;
    margin-top: 48px;
    padding: 0 0 16px 52px;
    position: relative;

    @media (max-width: $tablet) {
        margin: 24px 0;
        padding-left: 24px;
    }

    &::after {
        background: $black;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 5px;
    }
}

.author {
    color: $black;
    font-size: 1.25em;
    margin-top: 44px;
    transition: padding-top 0.2s $default-transition;

    svg {
        margin-right: 17px;
    }

    :global(ul) {
        line-height: 1.9;
        margin-top: 0;

        @media (max-width: $desktop) {
            font-size: 1em;
        }
    }

    :global(a) {
        color: currentColor;

        @media (max-width: $desktop) {
            font-size: 1em;
        }
    }

    @media (max-width: $desktop) {
        padding-top: 0;
        position: static;
    }

    @media (max-width: $desktop) {
        align-items: flex-start;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }

    @media (max-width: $tablet) {
        flex-flow: row;
    }

    @media (max-width: $mobile) {
        flex-flow: column;
    }
}

.name {
    display: block;
    font-weight: bold;
    margin-bottom: 4px;

    @media (max-width: $desktop) {
        font-size: 1em;
        line-height: 2.38;
    }
}

.social {
    color: $black;
    display: flex;
    flex-flow: column;
    margin-bottom: auto;
    overflow: hidden;
    position: relative;
    transition: all 0.2s $default-transition;
    z-index: 2;

    @media (max-width: $desktop) {
        align-self: flex-end;
        width: calc(50% - 16px);
    }

    @media (max-width: $mobile) {
        width: 100%;
    }

    li {
        margin-bottom: 8px;
        opacity: 1;
        transition: all 0.2s $default-transition;

        &:nth-of-type(1) {
            transition-delay: 0.1s;
        }

        &:nth-of-type(2) {
            transition-delay: 0.2s;
        }

        &:nth-of-type(3) {
            transition-delay: 0.3s;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        a {
            align-items: center;
            color: $black;
            display: flex;
            font-size: 1.125em;

            svg {
                color: currentColor;
                margin-right: 8px;
                flex-grow: 0;
                flex-shrink: 0;
                height: 24px !important;
                width: 24px !important;
            }
        }
    }
}

.button-container {
    @media (max-width: $tablet) {
        display: flex;

        svg {
            color: currentColor;
            height: 30px;
            margin-right: 8px;
            width: 30px;
        }
    }

    :global(.button) {
        height: 50px;
        margin-bottom: 16px;
        width: 100%;

        @media (max-width: $tablet) {
            margin-left: 16px;
            margin-right: 16px;
            width: calc(50% - 32px);
        }

        @media (max-width: $mobile) {
            max-width: 300px;
            width: 100%;
        }

        &:first-of-type {
            svg {
                height: 13.3px;
                width: 27.3px;
            }
        }

        &:nth-of-type(2) {
            svg {
                height: 20px;
                width: 24px;
            }
        }

        &:last-of-type {
            margin-bottom: 0;

            svg {
                height: 23px;
                width: 21px;
            }
        }
    }
}

.content {
    width: 100%;

    @media (max-width: $widescreen) {
        width: calc(100% - 44px - 216px);
    }

    @media (max-width: $desktop) {
        width: 100%;
    }
}

.intro {
    color: $black;
    font-size: $text-size-large;
    font-weight: bold;
    line-height: 1.5;
    margin: 0 0 32px;

    @media (max-width: $desktop) {
        font-size: $text-size-medium;
        margin-bottom: 24px;

        p {
            font-size: 25px;
            line-height: 1.16;
        }
    }
}

.description {
    left: -1px;
    margin-bottom: 80px;
    position: relative;
    z-index: 2;

    @media (max-width: $desktop) {
        margin-bottom: 56px;
    }

    h2 {
        color: $black;
        font-size: 2em;
        font-weight: bold;
        line-height: 0.84;
        margin-bottom: 18px;
        margin-top: 0;

        @media (max-width: $desktop) {
            font-size: $text-size-large;
            line-height: 1.16;
        }
    }

    h3 {
        color: $black;
        font-size: 2em;
        font-weight: bold;
        margin-bottom: 28px;
        margin-top: 50px;

        @media (max-width: $desktop) {
            font-size: 1.5625em;
            line-height: 1.16;
            margin: 24px 0 0;
        }
    }

    p {
        color: $black;
        font-size: 1.125em;
        line-height: 1.56;
        margin: 0 0 24px;

        @media (max-width: $desktop) {
            font-size: 1em;
            font-weight: 500;
            line-height: 1.75;
        }
    }

    ul {
        margin-bottom: 24px;

        li {
            color: $black;
            font-size: 1.125em;
            margin-bottom: 14px;
            min-height: 28px;
            position: relative;

            &:last-of-type {
                min-height: 28px;
            }

            span {
                left: 0;
                position: absolute;
                top: 0;
            }

            svg {
                margin-right: 16px;
            }
        }
    }
}

.video,
.description :global(.video) {
    height: 0;
    margin-bottom: 10px;
    overflow: hidden;
    padding-bottom: 52.5%;
    padding-top: 25px;
    position: relative;
}

.video iframe,
.description :global(.video) iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.checkmark {
    margin-right: 16px;
}

.content--missed .checkmark rect {
    fill: #cbcbcb;
}
