@import "../variables";

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}



select {
    appearance: none;
    background: none;
    border-color: $black;
    border-radius: 0;
    cursor: pointer;
    height: 44px;
    padding: 0;
    z-index: 2;
}

input,
select {
    background: $white;
    border: 0;
    border-radius: 0;
    color: $black;
    font-size: 1rem;
    font-weight: bold;
    height: 50px;
    min-height: 50px;
    outline: 0;
    padding-left: 11px;
    padding-right: 11px;
    width: 100%;

    &:focus {
        border-color: rgba(255, 255, 255, 0.7);
        outline-offset: -2px;
        outline-width: 2px;
    }

    &::placeholder {
        color: #808080;
    }

    &:disabled {
        background: rgba(255, 255, 255, 0.2);
    }

    @media screen and (max-width: $tablet) {
        font-size: 1.25em;
    }
}

textarea {
    background: $white;
    border: 0;
    border-radius: 0;
    color: $black;
    font-size: 1rem;
    font-weight: bold;
    min-height: 80px;
    outline: 1px solid $white;
    outline-offset: -2px;
    padding: 14px 11px;

    @media screen and (max-width: $tablet) {
        font-size: 1.25em;
    }

    &:focus {
        outline-offset: -2px;
        outline-width: 2px;
    }

    &::placeholder {
        color: #808080;
    }
}

label {
    cursor: pointer;
    display: inline-block;
}

.is-incorrect {
    border-color: $red;
    outline-color: $red;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.2) inset;
    -webkit-text-fill-color: $black;
    transition:
        background-color 5000s ease-in-out 0s,
        outline-offset 0.1s ease,
        border-color 0.2s ease;
}
