@import "../../styles/variables";

.grid {
    display: flex;
    margin-left: -35px;
    margin-right: -35px;
    flex: 1;
    flex-wrap: wrap;
    align-items: flex-start;

    @media (max-width: $desktop) {
        margin-left: -16px;
        margin-right: -16px;
    }

    @media (max-width: $tablet) {
        flex-flow: column;
        margin-bottom: -16px;
    }
}

.grid a {
    width: calc(50% - 70px);
    margin-left: 35px;
    margin-right: 35px;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    flex-basis: calc(50% - 70px);

    @media (max-width: $desktop) {
        width: calc(50% - 32px);
        margin-left: 16px;
        margin-right: 16px;
        flex-basis: calc(50% - 32px);
    }

    @media (max-width: $tablet) {
        width: calc(100% - 32px);
        margin-bottom: 24px;
    }
}

.grid--multiple {
    margin-bottom: -40px;

    a {
        margin-bottom: 64px;

        @media (max-width: $mobile) {
            margin-bottom: 24px;
        }
    }
}
