@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.modal {
    align-items: center;
    background: rgba(0, 0, 0, 0.65);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    min-width: 320px;
    position: fixed;
    top: 0;
    user-select: none;
    width: 100%;
    z-index: 11;

    @media (max-width: $tablet) {
        align-items: flex-start;
        padding-top: 16px;
    }
}

.modal :global(.close) {
    right: -60px;

    @media (max-width: $tablet) {
        right: 0;
        top: -65px;
    }
}

.content {
    background: $white;
    max-height: calc(100vh - 48px);
    max-width: 1288px;
    min-height: 200px;
    min-width: 1288px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
    user-select: none;
    width: auto;

    @media (max-width: $widescreen) {
        max-width: 976px;
        min-width: 976px;
    }

    @media (max-width: $desktop) {
        max-width: calc(100% - 32px);
        min-width: calc(100% - 32px);
        width: calc(100% - 32px);
    }
}

.content--loading {
    align-items: center;
    display: flex;
    justify-content: center;
}

.content--simple {
    max-width: 720px;
    min-height: 50px;
    min-width: 720px;
    padding: 24px;

    @media (max-width: $tablet) {
        max-width: calc(100% - 32px);
        min-width: calc(100% - 32px);
        padding: 16px;
        width: calc(100% - 32px);
    }
}

.close {
    align-items: center;
    background: $black;
    color: $white;
    cursor: pointer;
    display: flex;
    height: 64px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    user-select: none;
    width: 64px;

    svg {
        height: 22px;
        transition: opacity 0.2s $default-transition;
        width: 22px;
    }

    &:hover {
        svg {
            opacity: 0.65;
        }
    }

    @media (max-width: $tablet) {
        height: 48px;
        width: 48px;

        svg {
            height: 16px;
            width: 16px;
        }
    }
}

.article {
    padding-right: 86px;

    @media (max-width: $tablet) {
        padding-right: 64px;
    }
}

.title {
    composes: title from "../../components/modal-form/styles.module.scss";
}

.description {
    composes: description from "../../components/modal-form/styles.module.scss";
}

.not-found {
    color: $white;
    font-size: 1.25em;
    font-weight: bold;
    line-height: 1.63;
    text-align: center;
}
