@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.container {
    @include grid-row;

    background-color: $red;
    height: 572px;
    overflow: hidden;

    @media (max-width: $desktop) {
        height: auto;
    }
}

.wrapper {
    position: relative;

    @media (max-width: $desktop) {
        align-items: center;
        display: flex;
        flex-flow: column-reverse;
    }

    @media (max-width: $tablet) {
        display: none;
    }
}

.ambition {
    bottom: -50px;
    position: absolute;
    width: 811px;

    svg {
        overflow: visible !important;
    }

    @media (max-width: 1320px) {
        left: -100px;
    }

    @media (max-width: 1120px) {
        left: -140px;
    }

    @media (max-width: $desktop) {
        bottom: -8px;
        height: auto;
        left: 0;
        max-width: 816px;
        position: relative;
        width: 100%;
    }

    @media (max-width: $tablet) {
        margin-top: 100px;
        z-index: 2;
    }

    @media (max-width: $mobile) {
        margin-top: 30px;
    }
}

.ambition svg {
    height: 742px;
    width: 100%;

    @media (max-width: 1280px) {
        height: auto;
    }

    @media (max-width: $desktop) {
        left: -16px;
        position: relative;
        top: 150px;
        width: calc(100% + 32px);
    }

    @media (max-width: $mobile) {
        margin-top: -50px;
    }
}

.ambition img {
    bottom: -40px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;

    @media (max-width: $mobile) {
        bottom: -30px;
    }
}

.arrows {
    left: calc(811px - 24px);
    position: absolute;
    top: -80px;
    width: 629px;

    @media (max-width: 1320px) {
        left: calc(811px - 24px - 100px);
    }

    @media (max-width: 1120px) {
        left: calc(811px - 24px - 140px);
    }

    @media (max-width: $desktop) {
        left: -42px;
        top: 0;
        width: auto;
    }

    @media (max-width: $mobile) {
        top: 0;
        width: 326px;
    }
}

.text {
    position: absolute;
    right: 112px;
    top: 53px;
    width: 662px;

    @media (max-width: $desktop) {
        height: auto;
        left: 0;
        margin: 36px auto auto;
        max-width: 562px;
        position: relative;
        right: 0;
        width: calc(100% - 32px);
        z-index: 2;
    }

    @media (max-width: $tablet) {
        margin-top: 0;
    }
}

.go {
    bottom: -50px;
    left: calc(811px + 24px);
    position: absolute;

    @media (max-width: 1320px) {
        left: calc(811px + 24px - 100px);
    }

    @media (max-width: 1120px) {
        left: calc(811px + 24px - 140px);
    }

    @media (max-width: $desktop) {
        display: none;
    }
}
