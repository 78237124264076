@import "../../../styles/variables";
@import "../../../styles/utilities/mixins";

.grid {
    display: grid;
    grid-column-gap: 56px;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: $mobile) {
        grid-column-gap: 0;
        grid-row-gap: 24px;
        grid-template-columns: 1fr;
    }
}

.content {
    grid-column: 1 / 3;

    @media (max-width: $mobile) {
        grid-column: 1;
    }
}

.calculator {
    @include grid-column;

    grid-column: 3 / 4;

    @media (max-width: $mobile) {
        grid-column: 1;
    }
}

.title {
    @include title;
    @include title-spacing;

    font-family: $main-font;
    text-transform: uppercase;
}

.description {
    //p {
    //    @media (max-width: $mobile) {
    //        font-size: $text-size-small;
    //    }
    //}
}

.subtitle {
    @media (max-width: $mobile) {
        font-size: $text-size-normal;
    }
}

.form {
    composes: form from "../hover-tabs/styles.module.scss";
    grid-template-columns: 1fr;
    margin-top: 12px;
}

.field {
    composes: field from "../hover-tabs/styles.module.scss";
}

.label {
    composes: label from "../hover-tabs/styles.module.scss";
}

.error {
    composes: error from "../hover-tabs/styles.module.scss";
}

.range {
    background-color: transparent;
    border: 0;
    margin: 10px 0;
    outline: 0;
    width: 100%;
}

.button {
    composes: button from "../hover-tabs/styles.module.scss";
}
