@import "../../styles/variables";

.accordion {
    border-bottom: 2px solid $blue-dark;
    width: 100%;
}

.item {
    border-top: 2px solid $blue-dark;
    cursor: pointer;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.heading {
    display: flex;
    justify-content: flex-start;
    padding: 13px 0;

    @media (max-width: $tablet) {
        padding: 6px 0;
    }
}

.heading svg {
    margin-right: 19px;
    margin-top: 14px;

    @media only screen and (max-width: $desktop) {
        height: 19px;
        margin-right: 11px;
        margin-top: 14px;
        width: 11px;
    }
}

.title {
    font-size: 1.5em;
    font-weight: 900;
    margin: 8px 0 5px;
    width: calc(100% - 20px - 19px);

    @media only screen and (max-width: $desktop) {
        font-size: 1em;
        width: calc(100% - 15px - 11px);
    }
}

.content {
    margin-bottom: 16px;
    margin-top: -10px;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    padding-right: 64px;
    transition: all 0.5s ease;

    @media (max-width: $desktop) {
        padding-right: 44px;
    }

    p {
        font-size: 1.125em;
        letter-spacing: 0;
        line-height: 1.56;

        @media (max-width: $desktop) {
            font-size: 0.9375em;
        }
    }
}

.content a {
    color: $black;
    transition: opacity 0.1s $default-transition;

    &:hover {
        opacity: 0.6;
        text-decoration: none;
    }
}

.item--active .heading svg {
    transform: rotate(180deg);
}

.item--active .content {
    max-height: 600px;
    opacity: 1;
}
