@import "../../../styles/variables";
@import "../../../styles/utilities/mixins";

.block {
    display: flex;
    flex-direction: column;
}

.form {
    display: grid;
    gap: 12px 56px;
    grid-template-columns: 1fr 1fr;
    margin-top: 24px;
    width: 100%;

    @media (max-width: $tablet) {
        gap: 12px;
        grid-template-columns: 1fr;

        button {
            width: 100%;
        }
    }
}

.field {
    align-content: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;

    label {
        font-size: $text-size-normal;
        font-weight: bold;

        @media (max-width: $tablet) {
            font-size: $text-size-small;
        }
    }
}

.columns {
    display: grid;
    grid-auto-columns: 1fr 1fr;

    .col-1 {
        grid-column: 1;
    }

    .col-2 {
        grid-column: 2;

        @media (max-width: $tablet) {
            grid-column: 1;
        }
    }
}
