@import "../../styles/variables";

.layout {
    margin: 96px 0;

    @media (max-width: $desktop) {
        margin: 64px 0;
    }

    @media (max-width: $tablet) {
        margin: 32px 0;
    }
}

.wrapper {
    display: flex;

    @media (max-width: $tablet) {
        flex-direction: column;
    }
}
