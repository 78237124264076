@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.specialiteit {
    @include grid-column;

    margin-top: 24px;

    @media (max-width: $mobile) {
        margin-top: 0;
    }

    .specialiteit--title {
        color: $red;

        @media (max-width: $tablet) {
            margin-top: 12px;
        }

        @media (max-width: $mobile) {
            margin-top: 64px;
            text-align: center;
        }
    }

    .specialiteit--content {
        margin-top: 12px;

        @media (max-width: $mobile) {
            padding-top: 0;
            text-align: center;
        }
    }

    * {
        @media (max-width: $mobile) {
            margin-bottom: 12px;
        }
    }
}

.headline {
    font-size: $title-size-desktop;
}

.subhead {
    color: $red;
}

.skills {
    @include grid-row;

    @media (max-width: $tablet) {
        padding-top: 32px;
    }

    @media (max-width: $mobile) {
        padding-top: 12px;
    }

    .skills--column {
        @include grid-column;

        align-items: center;
    }

    .skills--title {
        color: $yellow;
        font-family: $title-font;
        font-size: $title-size-desktop;

        @media (max-width: $tablet) {
            font-size: $title-size-tablet;
        }
    }

    .skills--list {
        @include grid-column;

        align-self: center;
        justify-content: center;
    }

    .skills--list-item {
        align-self: center;
        padding-top: 12px;
        text-align: center;
    }

    .list--item {
        @include grid-row;

        align-items: center;
        padding: 12px;

        svg {
            display: none;
        }
    }
}
