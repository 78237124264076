@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.content {
    align-items: center;
    backface-visibility: hidden;
    background: none;
    background: $white;
    border: 0;
    color: $blue-light;
    cursor: pointer;
    display: inline-flex;
    font-size: $text-size-medium;
    font-weight: 700;
    height: 100%;
    justify-content: flex-start;
    margin-left: 0;
    min-height: 50px;
    min-width: 100%;
    overflow: hidden;
    padding: 5px 31px 10px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: 0.2s background $default-transition;
    user-select: none;
    white-space: inherit;
    width: 100%;
    z-index: 2;

    @media (max-width: $tablet) {
        font-size: $text-size-normal;
        padding: 7px 16px 10px;
    }
}

.button--active {
    svg {
        transform: translateX(4px);
    }
}

.button {
    background: none;
    border: 0;
    display: inline-block;
    font-weight: 900;
    height: 50px;
    min-width: 205px;
    padding: 0;
    position: relative;

    &:focus {
        outline: none;
    }

    svg {
        fill: currentColor;
        flex-shrink: 0;
        height: 32px;
        margin-left: 22px;
        position: relative;
        top: 2px;
        transform: translateX(0);
        transition: transform 0.2s $default-transition;
        width: 32px;

        @media (max-width: $tablet) {
            margin-left: 14px;
        }
    }

    &:hover {
        svg {
            transform: translateX(4px);
        }
    }

    .content {
        span {
            margin-left: 8px;
            text-align: left;
            white-space: nowrap;
        }
    }
}

.button--space-between .content {
    justify-content: space-between;
}

.button--no-transition:hover svg {
    transform: none;
}

.button--loading {
    pointer-events: none;
    user-select: none;

    .content {
        margin-left: 0;
        white-space: nowrap;
    }

    :global(.loading) {
        height: auto;
        margin-left: 0 !important;

        svg {
            height: 32px;
            margin: 0 0 0 -16px;
        }
    }
}

:global(.ie) .button--loading :global(.loading) div,
:global(.ie) .button--loading :global(.loading) div::after {
    height: 32px;
    width: 32px;
}

:global(.ie) .button--loading :global(.loading) div {
    border-width: 4px;
}

.button--wide {
    width: 100%;
}

.button--disabled {
    cursor: no-drop;
    opacity: 0.6;
    pointer-events: none;
    user-select: none;
}

.button--border-light-yellow .content {
    border-color: $yellow-light;
    color: $yellow-light;
}

.button--red {
    .content {
        background: $red;
        color: $white;
    }
}

.button--border {
    .content {
        border: 2px solid $white;
    }
}

.button--blue {
    .content {
        @include bg-blue-light;

        color: $white;
    }

    &:hover,
    &:active {
        .content {
            background: $blue-lighter;
        }
    }
}

.button--border-blue {
    .content {
        background: none;
        border-color: $blue-light;
        color: $white;
    }

    &:hover {
        .content {
            background: $blue-light;
        }
    }
}

.button--light-yellow {
    .content {
        background: $yellow-light;
        color: $white;
    }

    &:hover,
    &:active {
        .content {
            background: $yellow-lighter;
        }
    }
}

.button--light-yellow--active .content {
    border-color: $yellow-lighter;
}

.button--white {
    .content {
        background: $white;
        color: $blue-light;
    }

    &:active {
        .content {
            background: $light;
        }
    }

    &:hover {
        .content {
            background: $light;
        }
    }
}

.button--yellow .content {
    background-color: $yellow;
    color: $white;
}

.button--transparent-white {
    .content {
        background: transparent;
        color: $white;
        padding-left: 0;
    }
}

.button--transparent-blue {
    .content {
        background: transparent;
        padding-left: 0;
    }
}
