@import "../../styles/variables";

.content {
    color: $black;
    margin-bottom: 96px;
    margin-top: 64px;

    @media (max-width: 920px) {
        margin-bottom: 0;
        margin-top: 54px;
        width: 100%;
    }
}

.title {
    font-size: 3em;
    margin-bottom: 28px;
    padding-left: 24px;
    position: relative;

    &::before {
        background: $black;
        content: "";
        height: calc(100% - 13px);
        left: 0;
        position: absolute;
        top: 8px;
        width: 4px;
    }
}

.list {
    composes: list from "../../components/content-tabs/styles.module.scss";
    margin-bottom: 64px;

    @media (max-width: 920px) {
        margin-bottom: 42px;
    }
}

.description :global(.loading) {
    display: inline-block;
    margin-left: 25px;
    width: auto;
}

.description h2 {
    margin-bottom: 32px;
}
