@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.signup {
    background: $yellow;
    padding-bottom: 48px;
    padding-top: 104px;
    position: relative;

    @media (max-width: $tablet) {
        padding-top: 56px;
    }
}

.signup--single {
    background: $yellow-light;
}

.wrapper:global(.wrapper) {
    max-width: 654px;
}

.form {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    margin-top: 56px;
}

.form :global(.button) {
    white-space: nowrap;
}

.form input,
.form textarea {
    @media (max-width: $tablet) {
        font-size: 1em;
    }
}

.input-container {
    @include grid-column;

    margin-bottom: 32px;
}

.input-container__label {
    font-size: 1.125em;
    font-weight: bold;
    margin-bottom: 8px;
}

.input-container--half {
    margin-right: 68px;
    width: calc(50% - 34px);

    @media (max-width: 620px) {
        margin-right: 0;
        width: 100%;
    }
}

.notice {
    background: $purple;
    color: $white;
    font-weight: bold;
    margin-bottom: 32px;
    padding: 16px 24px;
    width: 100%;

    p {
        @media (max-width: $tablet) {
            font-size: 1em;
        }
    }

    br {
        @media (max-width: 620px) {
            display: none;
        }
    }
}

.notice :global(.button) {
    margin-top: 20px;
}

.button-container {
    @include grid-row;

    align-items: flex-start;
    justify-content: flex-start;

    @media (max-width: $mobile) {
        flex-flow: column;
        width: 100%;
    }
}

.button-container--final .back {
    margin-right: 68px;
    width: calc(50% - 34px);

    @media (max-width: $desktop) {
        margin-right: 0;
        width: auto;
    }
}

.error {
    align-items: center;
    background: $red;
    color: $white;
    display: flex;
    font-size: 1.125em;
    font-weight: bold;
    margin-left: 36px;
    min-height: 50px;
    padding: 14px 24px;

    @media (max-width: $tablet) {
        font-size: 1em;
        margin-bottom: 16px;
        margin-left: 0;
        width: auto;
    }
}

.error svg {
    align-self: flex-start;
    margin-right: 14px;
    margin-top: 5px;
    min-width: 14px;
    width: 14px;
}

.icon {
    align-items: center;
    display: flex;
    height: 17px;
    justify-content: flex-start;
    margin-left: 12px;
    width: 17px;

    svg {
        margin-left: 0;
        width: auto;
    }
}

.remove {
    display: none;
}

.file {
    left: -99999px;
    position: absolute;
    top: -999999px;
}

.arrow {
    height: 17.7px;
    width: 13.4px;
}

.subtitle {
    display: block;
    font-size: 2.5em;
    line-height: 0.88;
    margin-bottom: 10px;

    @media (max-width: $tablet) {
        font-size: 1.5625em;
        line-height: 0.88;
        margin-bottom: 14px;
    }
}

.title {
    font-family: $title-font;
    font-size: clamp(1.25em, 5vw, 3em);
    font-weight: bold;
    line-height: 0.84;
    margin-bottom: 28px;
    text-transform: uppercase;
    word-break: keep-all;

    @media (max-width: $tablet) {
        margin-bottom: 22px;
    }
}

.description {
    font-size: 1.125em;
    font-weight: 500;
    line-height: 1.72;
}

.signup--success {
    padding: 0;
}

.response {
    background: $yellow-light;
    color: $black;
    padding: 108px 0;

    @media (max-width: $desktop) {
        padding: 48px 0;
    }
}

.response-title {
    font-size: 4.375em;
    hyphens: auto;
    line-height: 1.07;
    margin-bottom: 32px;
    margin-top: 0;

    @media (max-width: $desktop) {
        font-size: clamp(24px, 5vw, 36px);
        line-height: 1.2;
        margin-bottom: 16px;
    }
}

.response-description {
    font-size: 1.125em;
    line-height: 1.72;

    @media (max-width: $desktop) {
        font-size: 1em;
        line-height: 1.75;
    }
}

.checkbox {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    margin-bottom: 32px;
    user-select: none;
    width: 100%;

    @media (max-width: $mobile) {
        margin-bottom: 16px;
    }
}

.checkbox--icon {
    align-items: center;
    background: $white;
    display: flex;
    height: 20px;
    justify-content: center;
    margin-right: 8px;
    min-width: 20px;
    overflow: hidden;
    width: 20px;

    svg {
        height: 10px;
        opacity: 1;
        transform: scale(1);
        transition: opacity 0.1s $default-transition,
            transform 0.2s $default-transition;
        width: 13px;
    }
}

.checkbox--icon-enter {
    opacity: 1;
    transform: scale(1);
}

.checkbox--label {
    margin-top: 1px;
}

.close {
    align-items: center;
    background: $black;
    color: $white;
    cursor: pointer;
    display: flex;
    height: 64px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 64px;

    @media (max-width: $tablet) {
        height: 48px;
        width: 48px;
    }

    svg {
        height: 22px;
        transition: opacity 0.2s $default-transition;
        width: 22px;

        @media (max-width: $tablet) {
            height: 16px;
            width: 16px;
        }
    }

    &:hover {
        svg {
            opacity: 0.65;
        }
    }
}

.upload :global(.button) :global(.content) {
    justify-content: center;
}

.upload :global(.button) :global(.loading) {
    height: 15px;
    margin-left: 0;
    width: 15px;

    svg {
        height: 15px;
        margin: -7.5px 0 0 -7.5px;
        top: 0;
        width: 15px;

        circle {
            stroke-width: 10px;
        }
    }
}

.upload :global(.button):hover .icon--complete svg {
    display: none;
}

.upload :global(.button):hover .icon .remove {
    display: block;
}
