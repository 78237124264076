@import "../../styles/variables";

.item {
    background: $white;
    display: flex;
    flex-flow: column;
    margin-right: 70px;
    transition: transform 0.2s $default-transition;
    position: relative;
    padding-bottom: 144px;
    min-height: 450px;
    margin-bottom: 64px;

    @media (max-width: $desktop) {
        padding-bottom: 111px;
    }

    @media (max-width: $tablet) {
        min-height: 1px;
    }
}

.image {
    overflow: hidden;
    height: 348px;
    position: relative;

    @media (max-width: $desktop) {
        height: 368px;
    }

    @media (max-width: $tablet) {
        height: 194px;
    }
}

.background {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
    transition: transform 0.2s $default-transition;

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        left: 0;
        top: 0;
        transition: background 0.2s $default-transition;
    }
}

.play {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 2;
    width: 126px;
    height: 126px;
    border: 4px solid $white;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

    @media (max-width: $tablet) {
        width: 66px;
        height: 66px;
    }
}

.play svg {
    position: relative;
    left: 2px;
    height: 47px;

    @media (max-width: $tablet) {
        height: 29px;
    }
}

.content {
    background: $white;
    padding: 24px 32px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    @media (max-width: $desktop) {
        padding: 16px 16px 8px;
    }
}

.title {
    font-weight: bold;
    font-size: 3.125em;
    color: $black;
    display: block;
    line-height: 1;
    hyphens: auto;
    margin-bottom: 16px;

    @media (max-width: $widescreen) {
        font-size: 2.5em;
    }

    @media (max-width: $desktop) {
        font-size: 1.875em;
    }
}

.subtitle {
    font-size: 1.1875em;
    line-height: 1.42;
    color: $black;
    display: block;
    transition: all 0.2s $default-transition;

    @media (max-width: $desktop) {
        font-size: 1.125em;
        line-height: 1.17;
    }
}

.more {
    font-size: 1.25em;
    color: $blue-light;
    font-weight: bold;
    max-height: 1px;
    display: inline-block;
    margin-top: 0;
    opacity: 0;
    transition: all 0.2s $default-transition;
}

.more svg {
    margin-left: 5px;
    position: relative;
    top: -1px;
}

.item:hover .background {
    transform: scale(1.05);

    &::after {
        background: rgba(0, 0, 0, 0.1);
    }
}

.item:hover .more {
    transform: translateY(-4px);
    max-height: 44px;
    margin-top: 44px;
    opacity: 1;
}

.item:hover .subtitle {
    transform: translateY(-4px);
    margin-bottom: 8px;
    max-height: 500px;
    opacity: 1;
}
