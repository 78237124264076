@import "../../../styles/variables";
@import "../../../styles/utilities/mixins";

.wrapper {
    @include grid-column;
}

.title {
    font-family: $title-font;
    font-size: $title-size-desktop;
    margin-bottom: 12px;

    @media (max-width: $tablet) {
        font-size: $title-size-tablet;
    }

    @media (max-width: $mobile) {
        font-size: $title-size-mobile;
    }
}

.subtitle {
    color: $white;

    &--red {
        color: $red;
    }
}

.headline {
    font-size: $title-size-desktop;

    @media (max-width: $tablet) {
        font-size: $title-size-tablet;
        text-align: center;
    }

    @media (max-width: $mobile) {
        color: $white;
        font-size: $title-size-mobile;
        padding-top: 24px;

        br {
            display: none;
        }
    }
}

.list {
    @include grid-column;

    align-items: center;

    .list--item {
        @include grid-row;

        align-items: center;
        font-size: $text-size-normal;

        svg {
            flex-shrink: 0;
            height: 32px;
            margin-right: 16px;
            width: 32px;

            @media (max-width: $mobile) {
                height: 24px;
                width: 24px;
            }
        }
    }
}

.buttons {
    @include grid-row;

    justify-content: flex-end;
    margin: 24px 0;

    @media (max-width: $mobile) {
        flex-direction: column-reverse;
        justify-content: center;
        margin: 0;
    }

    &--centered {
        align-items: center;
        justify-content: center;

        h3 {
            margin-right: 24px;

            @media (max-width: $mobile) {
                margin-right: 0;
            }
        }
    }
}

.content {
    margin-bottom: 24px;

    p {
        font-size: $text-size-normal;

        @media (max-width: $mobile) {
            font-size: $text-size-small;
        }
    }
}
