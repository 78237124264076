@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.intro {
    background: $light;
}

.hero {
    @include bg-image;

    width: 100%;
    height: 200px;
}

.wrapper {
    background: $black;
    color: $white;
    margin-top: -50px;
    padding: 1rem;
}

.title {
    color: $red;
    font-size: 2rem;
}

.details {
    display: grid;
    font-weight: bold;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 1rem;

    div {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        &:last-child {
            grid-column: span 2 / span 2;
        }
    }

    span {
        color: $yellow;
    }
}

.quick {
    margin: 2rem 0 1rem;

    h3 {
        font-size: $text-size-normal;
    }

    .links {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 1rem;
        gap: 1rem;

        .link {
            align-items: center;
            display: flex;
            gap: 0.5rem;

            svg {
                color: $blue-bis;
                height: 16px;
                width: 16px;
            }
        }
    }
}

.function {
    margin: 2rem auto;
}

.offer {
    ul {
        margin-left: 0;
        margin-top: 2rem;

        li {
            display: flex;
            font-size: $text-size-normal;
            line-height: 1;
            margin-bottom: 1.5rem;
        }

        li:before {
            content: url("https://careerrebels.nl/check-purple.svg");
            line-height: 24px;
            padding-right: 1rem;
        }
    }
}

.requirements {
    ul {
        margin-left: 0;
        margin-top: 2rem;

        li {
            display: flex;
            font-size: $text-size-normal;
            line-height: 1;
            margin-bottom: 1.5rem;
        }

        li:before {
            content: url("https://careerrebels.nl/check-yellow.svg");
            line-height: 24px;
            padding-right: 1rem;
        }
    }
}

.client {
}

.recruiter {
    display: flex;
    flex-direction: column;

    a {
        color: $white;
        font-size: $text-size-normal;
    }

    .recruiterDetails {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        gap: 0.5rem;
        margin-top: 1rem;

        svg {
            height: 24px;
            width: 24px;
        }
    }

    .recruiterTitle {
        color: $purple;
        line-height: 1.5;
        padding-bottom: 1rem;
    }
}

.contact {
    padding: 1rem 0;

    a {
        color: $white;
        font-weight: bold;
    }
}
