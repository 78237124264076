@import "../../styles/variables";

:local {
    .recruiters {
        background: $light;
        color: $black;
        padding: 64px 0;

        @media (max-width: $desktop) {
            padding: 48px 0;
        }

        @media (max-width: $tablet) {
            padding: 32px 0;
        }

        @media (max-width: $mobile) {
            padding: 24px 0;
        }

        .title {
            color: $yellow;
            font-family: $title-font;
            font-size: 2.5em;
            text-align: center;

            @media (max-width: $mobile) {
                font-size: 2em;
            }
        }
    }

    .location {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 64px 0;

        @media (max-width: $tablet) {
            margin: 48px 0;
        }

        @media (max-width: $mobile) {
            margin: 32px 0;
        }

        h2 {
            background-color: $black;
            color: $white;
            font-family: $title-font;
            font-size: 4em;
            padding: 16px 24px;
            transform: rotate(5deg);

            @media (max-width: $tablet) {
                font-size: 3em;
            }

            @media (max-width: $mobile) {
                font-size: 2em;
            }
        }
    }
}
