@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.list {
    @include grid-column;

    line-height: 2.38;
    margin-right: 71px;

    @media (max-width: $widescreen) {
        margin-right: 42px;
    }

    &:last-of-type {
        margin-right: 0;
    }

    @media (max-width: $desktop) {
        margin-right: 0;
    }
}

.address {
    @include grid-column;

    font-style: normal;
}

.address a {
    color: currentColor;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
        font-weight: bold;
    }
}

.address a svg {
    height: 11px;
    margin-left: 4px;
    margin-right: 0;
    opacity: 0;
    transform: translateX(-4px);
    transition: all 0.2s $default-transition;
    width: 22px;
}

.address a:hover svg {
    opacity: 1;
    transform: translateX(0);
}

.list :global(a) {
    color: currentColor;

    &:hover {
        text-decoration: underline;
    }
}

.list svg {
    margin-right: 17px;
}

.list--large {
    font-size: 1.25em;
    margin-bottom: 32px;
    margin-right: 0;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.list--footer {
    margin-bottom: 24px;
    margin-left: 24px;
    margin-right: 24px;
    width: calc(33.333333% - 48px);

    /*@media (min-width: $desktop) {
        margin-left: 16px;
        margin-right: 16px;
        width: calc(33.333333% - 32px);
    }*/

    @media (max-width: $tablet) {
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }
}

.list--footer .address {
    @media (max-width: $tablet) {
        margin-top: 32px;
        order: 2;
    }
}

.list--footer .address .name {
    @media (max-width: $tablet) {
        display: none;
    }
}

.list--footer ul {
    @media (max-width: $tablet) {
        line-height: 1.9;
        margin-top: 0;
        order: 1;
    }
}

.contact {
    line-height: 2.38;
    margin-left: 0;
    margin-top: 22px;
    white-space: nowrap;

    svg {
        height: 18px;
        width: 18px;

        @media (max-width: $desktop) {
            display: none;
        }
    }
}
