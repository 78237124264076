@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.modal {
    background: $blue;
    composes: modal from "../../components/modal-form/styles.module.scss";
}

.image {
    composes: image from "../../components/modal-form/styles.module.scss";
    margin-left: -126px;
    margin-right: 32px;
    max-width: calc(100% - 554px + 126px - 32px);
    position: relative;
    width: calc(100% - 554px + 126px - 32px);
    z-index: 2;

    @media (max-width: $desktop) {
        left: -16px;
        margin-left: 0;
        margin-right: 0;
        max-width: 615px;
        min-width: 1px;
        position: relative;
        width: calc(100% + 32px);
    }
}

.form {
    composes: form from "../../components/modal-form/styles.module.scss";
    padding-right: 0;
    width: 554px;

    @media (max-width: $desktop) {
        padding-left: 0;
    }

    @media (max-width: $mobile) {
        width: 100%;
    }
}

.form input,
.form textarea {
    font-size: 1.125em;

    @media (max-width: $tablet) {
        font-size: 1em;
    }
}

.steps {
    composes: steps from "../../components/modal-form/styles.module.scss";
}

.title {
    composes: title from "../../components/modal-form/styles.module.scss";
    font-size: 4.375em;
    line-height: 0.89;
    margin-bottom: 48px;

    @media (max-width: 1240px) {
        font-size: 3.125em;
        min-width: 1px;
    }

    @media (max-width: $tablet) {
        font-size: 2.5em;
        line-height: 1.16;
        margin-bottom: 24px;
    }
}

.description {
    composes: description from "../../components/modal-form/styles.module.scss";
}

.fields {
    color: $white;
    composes: fields from "../../components/modal-form/styles.module.scss";
    padding-right: 54px;

    @media (max-width: 1140px) {
        padding-right: 0;
    }
}

.notice {
    composes: notice from "../../components/signup-form/styles.module.scss";
}

.button-container {
    composes: button-container from "../../components/signup-form/styles.module.scss";
    padding-right: 54px;

    @media (max-width: 1140px) {
        padding-right: 0;
    }
}

.button-container--final {
    justify-content: space-between;

    @media (max-width: $tablet) {
        flex-flow: column;
    }
}

.button-container--final .back {
    width: calc(50% - 34px);

    @media (max-width: $tablet) {
        margin-right: 0;
        width: auto;
    }
}

.error {
    composes: error from "../../components/signup-form/styles.module.scss";
    margin-left: 0;
    margin-top: 16px;
}

.back {
    color: $white;
    composes: back from "../../components/modal-form/styles.module.scss";
    justify-content: flex-start;
}

.article {
    color: $white;
}

.article--intro {
    margin-bottom: 28px;
}

.article--success {
    composes: article--success from "../../components/modal-form/styles.module.scss";
}

.article--success :global(.button) {
    margin-top: 28px;
}

.count {
    color: $white;
    display: inline-block;
    font-family: $title-font;
    font-size: 1.125em;
    font-weight: bold;
    margin-bottom: 8px;
}

.labels {
    color: $white;
    display: flex;
    font-size: 1.125em;
    font-weight: bold;
    justify-content: space-between;
    margin-bottom: 50px;
    margin-top: 28px;

    span {
        &:last-of-type {
            text-align: right;
        }
    }
}

.slider {
    position: relative;
}

.slider :global(.noUi-target) {
    background: none;
    border: 0;
    border-radius: 0;
    box-shadow: none;
}

.slider :global(.noUi-handle) {
    background: none;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    color: $white;
    height: 48px;
    outline: none;
    top: -16px;
    transition: all 0.2s ease;

    @media (max-width: $mobile) {
        height: 38px;
        top: -11px;
    }

    &::before,
    &::after {
        display: none;
    }
}

.slider :global(.noUi-tooltip) {
    background: $black;
    border: 0;
    border-radius: 0;
    bottom: 0;
    color: $white;
    display: flex;
    font-family: $title-font;
    font-size: 1.25em;
    font-weight: bold;
    line-height: 48px;
    padding: 0 14px;
    text-transform: uppercase;
    top: 0;
    transition: all 0.2s $default-transition;

    @media (max-width: $mobile) {
        font-size: 1em;
        line-height: 38px;
        padding: 0 12px;
    }
}

.slider :global(.noUi-origin) {
    transition: all 0.2s ease;
}

.slider-steps {
    bottom: 0;
    display: flex;
    height: 26px;
    justify-content: space-between;
    margin: auto;
    pointer-events: none;
    position: absolute;
    top: 0;
    user-select: none;
    width: 100%;

    &::after {
        background: $yellow-light;
        bottom: 0;
        content: "";
        height: 8px;
        left: 0;
        margin: auto;
        position: absolute;
        top: 0;
        width: 100%;

        @media (max-width: $mobile) {
            height: 4px;
        }
    }
}

.slider-steps span {
    background: $yellow-light;
    display: block;
    height: 100%;
    width: 8px;

    @media (max-width: $mobile) {
        width: 4px;
    }

    &:last-of-type {
        position: relative;
        right: -1px;
    }
}

html .slider--first :global(.noUi-horizontal .noUi-handle) {
    right: -35px;

    @media (min-width: $mobile) {
        right: -59px;
    }
}

html .slider--last :global(.noUi-horizontal .noUi-handle) {
    right: 0;

    @media (min-width: $mobile) {
        right: 27px;
    }
}

.gradient {
    background: radial-gradient(circle, #fff 0%, #fff0 70%);
    bottom: -228px;
    height: 914px;
    left: -126px;
    opacity: 0.4;
    pointer-events: none;
    position: absolute;
    width: 914px;
    z-index: 1;

    @media (max-width: $widescreen) {
        left: -246px;
    }

    @media (max-width: $desktop) {
        bottom: -268px;
        height: 614px;
        width: 614px;
    }
}

.final {
}
