@for $i from 1 through 40 {
    .p-#{$i} {
        padding: calc($i / 4)rem;
    }

    .pb-#{$i} {
        padding-bottom: calc($i / 4) rem;
    }

    .pl-#{$i} {
        padding-left: calc($i / 4) rem;
    }

    .pr-#{$i} {
        padding-right: calc($i / 4) rem;
    }

    .pt-#{$i} {
        padding-top: calc($i / 4) rem;
    }

    .px-#{$i} {
        padding: 0 calc($i / 4) rem;
    }

    .py-#{$i} {
        padding: calc($i / 4) rem 0;
    }
}

@for $i from 1 through 40 {
    .m-#{$i} {
        margin: calc($i / 4) rem;
    }

    .mb-#{$i} {
        margin-bottom: calc($i / 4) rem;
    }

    .ml-#{$i} {
        margin-left: calc($i / 4) rem;
    }

    .mr-#{$i} {
        margin-right: calc($i / 4) rem;
    }

    .mt-#{$i} {
        margin-top: calc($i / 4) rem;
    }

    .mx-#{$i} {
        margin: 0 calc($i / 4) rem;
    }

    .my-#{$i} {
        margin: calc($i / 4) rem 0;
    }
}
