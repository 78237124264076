@import "../../../styles/variables";
@import "../../../styles/utilities/mixins";

.block {
    display: flex;
    justify-content: center;
}

.inner {
    background: $black;
    color: $white;
    font-family: $stolz-font;
    font-size: 3rem;
    font-weight: bold;
    max-width: 75%;
    padding: 1rem;
    transform: rotate(-5deg);

    @media (max-width: $tablet) {
        font-size: 1.5rem;

        br {
            display: none;
        }
    }
}
