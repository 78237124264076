@import "../../styles/variables";

.stories {
    background: $light;
    color: $black;
    padding: 78px 0;

    @media (max-width: $desktop) {
        padding: 48px 0;
    }

    @media (max-width: $mobile) {
        padding: 32px 0;
    }
}

.empty {
    //composes: empty from "../../components/vacancy-overview/styles.module.scss";
}
