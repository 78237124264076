@import "../../styles/variables";
@import "../../styles/utilities/mixins";

.header {
    @include grid-row;

    background-color: $white;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.3);
    height: 80px;
    justify-content: space-between;
    left: 0;
    max-width: 100vw;
    position: fixed;
    right: 0;
    top: 0;
    transition: top 0.1s $default-transition;
    user-select: none;
    z-index: 99;

    @media (max-width: $widescreen) {
        height: 60px;
    }

    @media (max-width: $desktop) {
        justify-content: center;
        z-index: 11;
    }

    @media (max-width: $mobile) {
        height: 40px;
    }

    &::after,
    &::before {
        background: $yellow;
        content: "";
        display: none;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 50%;

        @media (max-width: $desktop) {
            display: block;
        }
    }

    &::after {
        background: $black;
        left: auto;
        right: 0;
    }
}

.header--unpinned {
    @media (max-width: $desktop) {
        top: -60px;
    }

    @media (max-width: $mobile) {
        top: -40px;
    }
}

.logo {
    color: $white;
    display: block;
    height: 80px;
    position: relative;
    z-index: 2;

    svg {
        height: 80px;
        width: 560px;
    }

    @media (max-width: $widescreen) {
        height: 60px;

        svg {
            height: 60px;
            width: 420px;
        }
    }

    @media (max-width: $mobile) {
        svg {
            height: 40px;
            width: 280px;
        }
    }
}

.headerNav {
    display: flex;
    float: right;
    height: 100%;
    position: absolute;
    //left: 0;
    right: 0;
    top: 0;
    width: auto;

    @media (max-width: $desktop) {
        bottom: 0;
        height: 55px;
        position: fixed;
        top: auto;
        z-index: 10;
    }
}

.item {
    align-items: center;
    color: $yellow;
    cursor: pointer;
    display: flex;
    font-family: $main-font;
    font-size: $text-size-large;
    font-weight: bold;
    height: 100%;
    justify-content: center;
    padding: 0 41px;
    text-decoration: none;

    @media (max-width: $widescreen) {
        padding: 0 24px;
    }

    @media (max-width: $desktop) {
        display: none;
    }

    &:first-of-type {
        @media screen and (max-width: $desktop) {
            display: none;
        }
    }

    &:last-of-type {
        background-color: $black;
        color: $white;
        width: 320px;

        @media (max-width: $desktop) {
            display: flex;
            width: 100vw;
        }

        @media (max-width: $tablet) {
            background-color: $blue;
        }
    }
}

.vacancies svg {
    opacity: 0;
    position: relative;
    top: 2px;
    transform: translateX(4px);
    transition: all 0.2s $default-transition;
    width: 0;
}

.vacancies:hover svg {
    margin-left: 12px;
    opacity: 1;
    transform: translateX(0);
    width: 38px;
}

.overlay {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
    );
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: opacity 0.2s $default-transition;
    width: 100%;
    z-index: 8;
}

.overlay--active {
    opacity: 1;
}
