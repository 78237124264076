@import "../../../styles/variables";
@import "../../../styles/utilities/mixins";

.tabs {
    @include grid-column;
}

.navigation {
    display: grid;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

    @media (max-width: $mobile) {
        grid-row-gap: 12px;
        grid-template-columns: 1fr;
    }

    .tab {
        @include bg-purple;

        cursor: pointer;
        padding: 24px;
        text-align: center;
        user-select: none;

        &.active {
            background: $yellow;
        }

        .title {
            text-transform: uppercase;
        }

        .subtitle {
            display: inline-block;
            padding-top: 12px;
        }
    }
}

.content {
    @include grid-column;

    .block {
        display: none;
        margin-top: 24px;
        transition: all 5s $default-transition;

        &.active {
            @include grid-column;
        }

        //p {
        //    @media (max-width: $mobile) {
        //        font-size: $text-size-small;
        //    }
        //}
    }
}

.wrapper {
    margin-top: 56px;

    @media (max-width: $mobile) {
        margin-top: 24px;
    }
}

.form {
    display: grid;
    grid-column-gap: 56px;
    grid-row-gap: 12px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto [last-line];
    margin-top: 24px;

    @media (max-width: $mobile) {
        //grid-row-gap: 0;
        grid-template-columns: 1fr;
        margin-top: 12px;
    }
}

.field {
    @include grid-column;

    @media (max-width: $mobile) {
        margin-top: 12px;
    }

    &:last-of-type {
        align-self: flex-end;
        display: block;
        grid-column: 1 / -1;
    }
}

.label {
    display: inline-block;
    font-size: $text-size-normal;
    margin-bottom: 12px;

    @media (max-width: $mobile) {
        margin-bottom: 4px;
    }
}

.error {
    display: inline-block;
    height: 24px;
    line-height: 24px;
    max-height: 24px;
}

.arrows {
    margin-top: 56px;

    @media (max-width: $mobile) {
        display: none;
    }
}

.button {
    align-self: flex-end;
    backface-visibility: hidden;
    background: $blue-light;
    border: 0;
    color: $white;
    cursor: pointer;
    font-size: $text-size-normal;
    font-weight: 700;
    height: 50px;
    margin-top: 12px;
    max-height: 50px;
    min-width: 280px;
    outline: none;
    padding: 10px 30px;
    transition: 0.2s background $default-transition;
    z-index: 2;

    @media (max-width: $mobile) {
        width: 100%;
    }

    &:hover {
        background: $blue-lighter;
    }
}
