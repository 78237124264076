@import "../../styles/variables";

.our-vacancies {
    background-image: radial-gradient(
        circle at 28% 75%,
        $purple-light,
        $purple
    );
    padding-top: 48px;
}

.wrapper {
    display: flex;

    @media (max-width: 920px) {
        flex-flow: column-reverse;
    }
}

.right {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin-left: 74px;
    max-width: calc(100% - 566px - 74px);
    padding-top: 32px;

    @media (max-width: 1280px) {
        max-width: calc(100% - 400px - 74px);
    }

    @media (max-width: $desktop) {
        margin-left: 32px;
        max-width: calc(100% - 335px - 32px);
    }

    @media (max-width: $desktop) {
        margin-left: 0;
        max-width: 100%;
        padding-top: 0;
    }
}

.right :global(.button) {
    align-self: flex-start;
    margin-left: 32px;

    @media (max-width: 920px) {
        margin-left: 16px;
    }
}

.right--empty-result {
    align-items: flex-end;
    flex-flow: row;
    justify-content: space-between;
    margin-left: 0;
    max-width: 100%;
    padding-top: 0;
    width: 100%;
}

.title {
    color: $white;
    composes: title from "../../components/image-cta/styles.module.scss";
    font-size: 3.125em;
    line-height: 1;
    margin-bottom: 16px;
    margin-left: 32px;
    max-width: 430px;

    @media (max-width: $desktop) {
        font-size: 2.1875em;
        margin-left: 0;
        padding-left: 16px;
    }

    b,
    strong {
        color: $yellow;
    }
}

.description {
    color: $white;
    font-size: 1.125em;
    line-height: 1.63;
    margin-bottom: 32px;
    max-width: 360px;
    padding-left: 32px;

    @media (max-width: 920px) {
        font-size: 1em;
        padding-left: 16px;
    }
}

.image {
    margin-right: -126px;
    margin-top: 24px;
    max-width: calc(560px + 126px);
    width: calc(100% + 126px);

    @media (max-width: $widescreen) {
        margin-right: 0;
        width: 100%;
    }

    @media (max-width: $desktop) {
        margin-top: 48px;
    }

    @media (max-width: $tablet) {
        left: -16px;
        position: relative;
        width: calc(100% + 32px);
    }
}

.content {
    @media (max-width: $widescreen) {
        margin-right: 32px;
        max-width: 320px;
        min-width: 320px;
    }

    @media (max-width: $desktop) {
        max-width: 360px;
        min-width: 360px;
        padding-top: 0;
    }

    @media (max-width: $mobile) {
        min-width: 100%;
    }
}

.right--empty-result .content {
    padding-bottom: 104px;

    @media (max-width: $widescreen) {
        padding-bottom: 56px;
    }

    @media (max-width: $desktop) {
        padding-bottom: 0;
    }
}

.right--empty-result .image {
    @media (max-width: $widescreen) {
        margin-right: 32px;
        width: calc(100% - 320px - 32px - 32px);
    }

    @media (max-width: $desktop) {
        margin-right: 0;
        max-width: 540px;
        width: calc(100% - 320px - 32px);
    }

    @media (max-width: $mobile) {
        left: -16px;
        position: relative;
        width: calc(100% + 32px);
    }
}
