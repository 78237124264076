@import "../../../styles/variables";
@import "../../../styles/utilities/mixins";

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}

.grid {
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: $mobile) {
        gap: 1rem;
        grid-template-columns: 1fr;
    }
}

.item {
    background: $yellow;
    display: flex;
    flex-direction: column;
    height: 250px;
    justify-content: space-between;
    overflow: hidden;
    padding: 1.5rem;
    position: relative;

    @media (max-width: $tablet) {
        height: 150px;
    }

    &:nth-of-type(1) {
        :local(.arrows) {
            color: $blue;
        }
    }

    &:nth-of-type(2) {
        background: $blue-light;

        :local(.arrows) {
            color: $yellow-light;
        }
    }

    &:nth-of-type(3) {
        background: $purple;
        color: $white;

        :local(.arrows) {
            color: $yellow-light;
        }
    }

    &:nth-of-type(4) {
        background: $yellow-light;

        :local(.arrows) {
            color: $red;
        }
    }
}

.title {
    font-size: 2.5rem;
    text-transform: uppercase;
    z-index: +1;

    @media (max-width: $tablet) {
        font-size: 1.5rem;
    }
}

.arrows {
    position: absolute;
    right: -50%;
    transform: rotate(135deg) scale(1.5);
}
