@import "../variables";

.video {
    height: auto;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
}

.video iframe,
.video object,
.video embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
