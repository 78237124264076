$main-font: "Space Grotesk", sans-serif;
$title-font: "Space Grotesk", sans-serif;
$stolz-font: "Stolzl Display", sans-serif;

$white: #fff;
$light: #ededed;
$dark: #505055;
$black: #000;
$purple: #714d99;
$purple-light: #aa85ca;
$yellow: #f6ac02;
$yellow-light: #fdcf7a;
$yellow-lighter: #f1c46f;
$red: #e95550;
$blue: #0074bd;
$blue-light: #4cbcc9;
$blue-lighter: #00b0b8;
$blue-bis: #02aee0;
$blue-dark: #172b48;
$green: #279b27;
$rook: #e3e3e2;
$zwart: #1d1d1b;

$default-transition: cubic-bezier(0.35, 0, 0.65, 1);

$mobile: 480px;
$tablet: 768px;
$desktop: 1024px;
$widescreen: 1200px;

$title-size-mobile: 20px;
$title-size-tablet: 24px;
$title-size-desktop: 36px;

$text-size-small: 1rem;
$text-size-normal: 1.125rem;
$text-size-medium: 1.25rem;
$text-size-large: 1.5rem;
