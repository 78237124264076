@import "../../styles/variables";

.navigation {
    padding: 40px;

    a {
        color: $black;
        cursor: pointer;
        display: block;
        margin-bottom: 25px;

        &:last-of-type {
            margin-bottom: 0;
        }

        &.hide-on-desktop {
            display: none;

            @media (max-width: $desktop) {
                display: block;
            }
        }

        &.show-on-mobile {
            display: none;

            @media (max-width: $desktop) {
                display: block;
            }
        }

        svg {
            opacity: 0;
            position: relative;
            top: -2px;
            transition: transform 0.2s $default-transition,
                opacity 0.2s $default-transition;
        }

        &:hover {
            font-weight: bold;

            svg {
                opacity: 1;
                transform: translateX(4px);
            }
        }

        &.active {
            font-weight: bold;

            svg {
                opacity: 0;
                transform: translateX(24px);
            }

            &:hover {
                svg {
                    opacity: 0 !important;
                }
            }
        }
    }

    li:last-of-type {
        margin-bottom: 0;
    }

    @media (max-width: $tablet) {
        a {
            display: flex;

            svg {
                top: 5px;
                width: 38px;
            }
        }
    }
}

.navigation--enter-done {
    right: 0;

    @media (max-width: $tablet) {
        left: 0;
        right: auto;
        top: 0;
    }
}
